import _ from 'lodash';
import Section from "../components/Section";
import PageBackground from "../components/PageBackground";
import {Button} from "../components/Button";
import docsBackground from '../assets/backgrounds/documents.jpg';
import {BottomNavigator} from "../components/Navbar";
import UIState from "../store/uiState";
import {ModalTypes, STAKING_DISABLED} from "../constants";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {formatBalance, truncate} from "../utils";
import Wallet from "../store/models/wallet";
import {useEffect} from "react";
import {usePageVisibility} from "../hooks/usePageVisibility";
import {StakeAPYCTA, StakeAPYInfo} from "../components/CTAPill";
import {useTranslation} from "react-i18next";

const moment = require("moment");

export const selectCurrentAccount = state => state?.models?.currentAccount;
export const selectCurrentBalances = state => state?.models?.balances;
export const selectStakeWithdrawals = state => state?.models?.stakeWithdrawals;

const StakePage = () => {
    const {t} = useTranslation();
    const isVisible = usePageVisibility();
    const dispatch = useDispatch();
    const account = useSelector(selectCurrentAccount);
    const balances = useSelector(selectCurrentBalances);
    let stakeWithdrawals = useSelector(selectStakeWithdrawals);
    stakeWithdrawals = _.map(stakeWithdrawals, (withdrawal, i) => {
        const returnDateMoment = moment(withdrawal.returnDate);

        return {
            ...withdrawal,
            returnDateMoment: returnDateMoment,
            returnDateFromNow: returnDateMoment.fromNow(true),
            claimable: returnDateMoment.isBefore()
        }
    });
    const claimableWithdrawals = stakeWithdrawals.filter((withdrawal, i) => {
        return withdrawal.claimable;
    });

    const onStake = () => {
        dispatch(UIState.actions.showModal(ModalTypes.Stake));
    }

    const onUnstake = () => {
        dispatch(UIState.actions.showModal(ModalTypes.Unstake));
    }

    const onClaim = () => {
        const legacyClaimableWithdrawals = _.filter(claimableWithdrawals, (withdrawal) => {
            return withdrawal.legacy;
        });
        if(legacyClaimableWithdrawals.length > 0){
            dispatch(Wallet.actions.claimWithdrawnStake(true));
        }
        else{
            dispatch(Wallet.actions.claimWithdrawnStake());
        }
    }

    const onMetaMask = () => {
        dispatch(Wallet.actions.loginWithMetamask());
    }

    const onThetaWallet = () => {
        dispatch(Wallet.actions.loginWithWalletConnect());
    }

    const onDisconnect = () => {
        dispatch(Wallet.actions.disconnect());
    }

    useEffect(() => {
        dispatch(Wallet.actions.tryToRecoverWalletSession());
    }, []);

    useEffect(() => {
        console.log('isVisible', isVisible, account, balances);
        if(isVisible && account && !_.isEmpty(balances)){
            dispatch(Wallet.actions.getBalances());
            dispatch(Wallet.actions.getPendingStakeWithdrawals());
        }
    }, [isVisible]);


    return (
        <div className={'Page StakePage'}>
            <Section className={'TopSection'}>
                <PageBackground src={docsBackground}/>
                {
                    _.isNil(account) &&
                    <div className={'SectionContainer'}>
                        <StakeAPYInfo/>
                        <h1>
                            {t('stake.title')}
                        </h1>
                        <p>
                            {t('stake.description')}
                        </p>

                        <h4>
                            {t('stake.connect_your_wallet')}
                        </h4>
                        <Button color={'green'}
                                size={'large'}
                                title={'Theta Wallet'}
                                className={'ConnectWalletButton'}
                                onClick={onThetaWallet}
                        >
                            <img src={require('../assets/wallets/theta-wallet@2x.png')}/>
                        </Button>
                        <div className={'ConnectWalletButtonAttachment'}>
                            {t('stake.available_on_ios_and_android')}
                        </div>
                        <Button color={'green'}
                                size={'large'}
                                title={'Metamask'}
                                className={'ConnectWalletButton'}
                                onClick={onMetaMask}
                        >
                            <img src={require('../assets/wallets/metamask@2x.png')}/>
                        </Button>
                    </div>
                }
                {
                    !_.isNil(account) &&
                    <div className={'SectionContainer'}>
                        <StakeAPYInfo/>

                        {
                            STAKING_DISABLED &&
                            <div className={'WarningMessage'}>
                                {t('stake.staking_paused')}
                            </div>
                        }


                        <p className={'StakePage__connection'}>
                            <span className={'StakePage__connection-title'}>{t('stake.connected_to')}</span>
                            <span className={'StakePage__connection-account'}>{truncate(account)}</span>
                            <a className={'StakePage__connection-disconnect'}
                               onClick={onDisconnect}>
                                {t('stake.disconnect')}
                            </a>
                        </p>

                        <div className={'AccountBalances'}>
                            <div className={'AccountBalanceRow'}>
                                <div className={'AccountBalanceRow__title'}>
                                    {t('stake.balance')}
                                </div>
                                <div className={'AccountBalanceRow__value'}>
                                    {formatBalance(balances?.lavitaOnHand)}
                                </div>
                            </div>
                            <div className={'AccountBalanceRow'}>
                                <div className={'AccountBalanceRow__title'}>
                                    {t('stake.staked_plus_rewards')}
                                </div>
                                <div className={'AccountBalanceRow__value'}>
                                    {formatBalance(balances?.lavitaStaked)}
                                </div>
                            </div>
                        </div>
                        <div className={'StakePage__stake-actions'}>
                            <Button color={'green'}
                                    size={'large'}
                                    onClick={onStake}
                                    disabled={STAKING_DISABLED}
                            >
                                {t('stake.stake')}
                            </Button>
                            <Button color={'green'}
                                    size={'large'}
                                    onClick={onUnstake}
                            >
                                {t('stake.unstake')}
                            </Button>
                        </div>

                        {
                            stakeWithdrawals.length > 0 &&
                            <div className={'StakeWithdrawalsSection'}>
                                <div className={'StakeWithdrawalsSection__title'}>
                                    {t('stake.pending_withdrawals')}
                                </div>
                                {
                                    stakeWithdrawals.map((withdrawal, i) => {
                                        return (
                                            <div key={i}
                                                 className={'StakeWithdrawalRow'}
                                            >
                                                <div className={'StakeWithdrawalRow__returns'}>
                                                    {
                                                        !withdrawal.claimable &&
                                                        t('stake.available_in', {
                                                            time: withdrawal.returnDateFromNow
                                                        })
                                                    }
                                                    {
                                                        withdrawal.claimable &&
                                                        (withdrawal.legacy ? t('stake.available_to_claim_legacy') : t('stake.available_to_claim'))
                                                    }
                                                </div>

                                                <div className={'StakeWithdrawalRow__amount'}>
                                                    {formatBalance(withdrawal.amount)}
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                                <Button color={'grey'}
                                        size={'large'}
                                        onClick={onClaim}
                                        disabled={claimableWithdrawals.length === 0}
                                >
                                    {t('stake.claim')}
                                </Button>
                            </div>
                        }
                    </div>
                }
            </Section>

            <BottomNavigator
                prev={{
                    to: '/roadmap',
                    title: t('navbar.roadmap')
                }}
                next={{
                    to: '/',
                    title: t('navbar.home')
                }}/>
        </div>
    )
};

export default StakePage;