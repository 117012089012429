import { mergeUrl } from './url';

import {RequestState, Hosts, SessionErrorCodes} from '../constants';
import Storage from '../utils/storage';

const { REQUEST, SUCCESS, FAIL, NOT_AUTHORIZED } = RequestState;



// ===========================
// HELPERS
// ===========================

export function fetchOptions(method = 'get', body, headers = {}, mode = 'cors', host, credentials = 'include') {
    return {
        method,
        headers: {
            'X-Platform': 'web',
            ...(body ? { 'Content-Type': 'application/json' } : {}),
            ...headers,
        },
        body: JSON.stringify(body),
        credentials,
        mode,
    };
}

export function restUrl(url, params, host) {
    if(url.indexOf("http") !== -1) {
        return mergeUrl(url, params);
    }
    return mergeUrl(host + url, params);
}

export function isErrorResponse(code, json) {
    return Boolean(
        !json ||
        (code && code !== 200 && code !== 204) ||
        (json.code && json.code !== 200 && json.code !== 204) ||
        json.status === "ERROR" ||
        json.status === "FAIL");
}

export function authHeaders(session, token) {
    session = session ?? Storage.set();
    token = token ?? Storage.getUserToken();
    if(session && token) {
        return {
            'X-Auth-Session': session,
            'X-Auth-Token': token,
        }
    }
}

export function platformAuthHeaders(session, token) {
    session = session ?? Storage.getUserPlatformSession();
    token = token ?? Storage.getUserPlatformToken();
    if(session && token) {
        return {
            'X-Auth-Session': session,
            'X-Auth-Token': token,
        }
    }
}





// ===========================
// FETCH FUNCTION
// ===========================
async function _fetch(args) {
    let { url, params, method, dispatch,
        action, body, headers, host = Hosts.API, mode, data, credentials } = args;

    url = restUrl(url, params, host);
    let options = fetchOptions(method, body, headers, mode, host, credentials);

    // Disaptch Request
    if(dispatch && action)
        dispatch(request(action, url, params, body, data));

    // Do Fetch
    let response;
    try {
        response = await fetch(url, options);
    } catch (err) { // NETWORK ERROR
        if(typeof err === 'string') {
            console.log("URL is unreachable: ", url, err);
            return Promise.reject({ message: err });
        }
        console.log(err);
        return Promise.reject(err);
    }

    let json = {}
    try {
        if (method !== 'delete' && response.status !== 204) {
            json = await response?.json();
        }
    } catch (e) { // JSON PARSE ERROR
        console.log('err: ' + e?.message)
    }

    // RESPONSE ERRORS
    if(isErrorResponse(response.status, json)) {
        console.log("API ERROR", json, response, );
        let err = {
            message: json.message || json.status || 'Unknown Error',
            code: json.code || response.status || 'Unknown Code',
        }

        if(dispatch && action)
            dispatch(error(action, err, url, params, data));

        return Promise.reject({ ...err, action });
    }

    if(dispatch && action)
        dispatch(receive(action, json, url, params, data));

    json.headers = headers;
    return Promise.resolve(json);
}




// ===========================
// REQUST TYPES
// ===========================
export const get = (options) => _fetch({ ...options, method: 'get' });
export const post = (options) =>_fetch({...options, method: 'post' });
export const put = (options) =>_fetch({...options, method: 'put' });
export const del = (options) =>_fetch({...options, method: 'delete' });



// ===========================
// RESPONSE TYPES
// ===========================
export function request(action, url, params, body, data = null) {
    return {
        type: action,
        url,
        params,
        body,
        result: REQUEST,
        fetchAt: Date.now(),
        data,
    };
}

export function receive(action, json, url, params, data = null) {
    return {
        type: action,
        json,
        url,
        params,
        result: SUCCESS,
        receivedAt: Date.now(),
        data
    }
}

export function error(action, error, url, params, data) {
    return {
        type: action,
        ...error,
        url,
        params,
        result: FAIL,
        receivedAt: Date.now(),
        data,
    }
}

