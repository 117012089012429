import classNames from "classnames";
import Section from "../components/Section";
import PageBackground from "../components/PageBackground";
import platformBackground from '../assets/backgrounds/platform.jpg';
import userFlowBackground from '../assets/backgrounds/user_workflow.jpg';
import {BottomNavigator} from "../components/Navbar";
import {useTranslation} from "react-i18next";
import {buildLocalizedImagePath} from "../utils";

export const PlatformFlowCard = ({image, title, className}) => {
    const {t} = useTranslation();
    return (
        <div className={ classNames('PlatformFlowCard', className) }>
            <img className={'PlatformFlowCard__image'}
                 src={image}/>
            <div className={'PlatformFlowCard__title'}>
                {t(title)}
            </div>
        </div>
    )
}

const PlatformPage = () => {
    const {t, i18n} = useTranslation();
    const language = i18n.language;
    const marketplaceGraphic = buildLocalizedImagePath(language, 'platform/marketplace');
    const userGraphic1 = buildLocalizedImagePath(language, 'platform/user/1');
    const userGraphic2 = buildLocalizedImagePath(language, 'platform/user/2');
    const userGraphic3 = buildLocalizedImagePath(language, 'platform/user/3');
    const userGraphic4 = buildLocalizedImagePath(language, 'platform/user/4');

    return (
        <div className={'Page PlatformPage'}>
            <Section className={'MarketplaceSection'}
                     id={'overview'}
            >
                <PageBackground src={platformBackground}/>
                <div className={'SectionContainer'}>
                    <h2>
                        {t('platform.heading')}
                    </h2>
                    <h1>
                        {t('platform.title')}
                    </h1>
                    <h3>{t('platform.subtitle')}</h3>
                    <img src={marketplaceGraphic}
                         className={'MarketplaceSection__graphic'}/>
                    <p>
                        {t('platform.description_1')}
                        <br/>
                        <br/>{t('platform.description_2')}
                    </p>
                </div>
            </Section>

            <Section className={'UserWorkflowSection'}
                     id={'user-workflow'}
            >
                <PageBackground src={userFlowBackground}/>
                <div className={'SectionContainer'}>
                    <h2>
                        {t('platform.heading')}
                    </h2>
                    <h1>
                        {t('platform.user_workflow')}
                    </h1>

                    <div className={'PlatformFlowCards'}>
                        <PlatformFlowCard image={userGraphic1}
                                           title={t('platform.user_workflow_card_1')}/>
                        <PlatformFlowCard image={userGraphic2}
                                          title={t('platform.user_workflow_card_2')}/>
                        <PlatformFlowCard image={userGraphic3}
                                          title={t('platform.user_workflow_card_3')}/>
                        <PlatformFlowCard image={userGraphic4}
                                          title={t('platform.user_workflow_card_4')}/>
                    </div>
                </div>
            </Section>

            <BottomNavigator
                prev={{
                    to: '/about',
                    title: t('navbar.about')
                }}
                next={{
                    to: '/components',
                    title: t('navbar.components')
                }}/>
        </div>
    )
};

export default PlatformPage;