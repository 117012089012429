import React, {useEffect} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom'
import classNames from "classnames";
import {useDispatch, useSelector} from 'react-redux';
import {Provider} from 'react-redux';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations";
import {Navbar} from "./components/Navbar";
import HomePage from "./pages/Home";
import Footer from "./components/Footer";
import AboutPage from "./pages/About";
import PlatformPage from "./pages/Platform";
import ComponentsPage from "./pages/Components";
import DocumentsPage from "./pages/Documents";
import RoadmapPage from "./pages/Roadmap";
import AppsPage from "./pages/Apps";
import Demo from "./pages/Demo";
import Modals from "./components/Modals";
import {createAppStore} from "./store";
import reducer from './store/reducer';
import StakePage from "./pages/Stake";
import {history} from "./utils/history";
import toast, {Toaster} from 'react-hot-toast';
import ClaimPage from "./pages/Claim";
import NewsPage from "./pages/News";
import Wallet from "./store/models/wallet";

const initialState = {};
const store = createAppStore(reducer, initialState);

const parseLanguageCode = (code) => {
    // Extract the primary language subtag (before the hyphen if present)
    return code.split('-')[0];
};
const userLanguage = navigator.language || navigator.userLanguage;
const parsedLanguage = parseLanguageCode(userLanguage);
const defaultLanguage = 'en'; // Default language for your app
const supportedLanguages = ['en', 'ko']; // Languages your app supports

i18n
    .use(initReactI18next)
    .init({
        resources: translations,
        lng: supportedLanguages.includes(parsedLanguage) ? parsedLanguage : defaultLanguage,
        fallbackLng: defaultLanguage,
        interpolation: {
            escapeValue: false
        }
    });

const withNavBar = (Component) => () => (
    <React.Fragment>
      <Navbar/>
      <Component/>
    </React.Fragment>
);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
    return (
      <Provider store={store}>
          <Router historu={history}>
              <div className={classNames('App')}>
              <ScrollToTop/>
              <Routes>
                  <Route exact path="/" element={withNavBar(HomePage)()}/>
                  <Route exact path="/about" element={withNavBar(AboutPage)()}/>
                  <Route exact path="/platform" element={withNavBar(PlatformPage)()}/>
                  <Route exact path="/components" element={withNavBar(ComponentsPage)()}/>
                  <Route exact path="/docs" element={withNavBar(DocumentsPage)()}/>
                  <Route exact path="/roadmap" element={withNavBar(RoadmapPage)()}/>
                  <Route exact path="/apps" element={withNavBar(AppsPage)()}/>
                  <Route exact path="/demo" element={withNavBar(Demo)()}/>
                  <Route exact path="/apps/:app-slug" element={withNavBar(AppsPage)()}/>
                  <Route exact path="/stake" element={withNavBar(StakePage)()}/>
                  <Route exact path="/claim" element={withNavBar(ClaimPage)()}/>
                  <Route exact path="/news" element={withNavBar(NewsPage)()}/>
                  {/* default redirect to home page */}
                  <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              </div>
              <Toaster containerStyle={{
                  zIndex: 9999999999
              }}
                       toastOptions={{
                           success: {
                               style: {
                                   background: 'white',
                                   border: '1px solid #white',
                                   color: '#202224'
                               },
                               iconTheme: {
                                   primary: '#34E393',
                                   secondary: 'white',
                               },
                           },
                           error: {
                               style: {
                                   background: 'white',
                                   border: '1px solid white',
                                   color: '#202224'
                               },
                               iconTheme: {
                                   primary: '#f05555',
                                   secondary: 'white',
                               },
                           },
                       }}/>
              <Modals/>
          </Router>
      </Provider>
  );
}

export default App;
