import {useEffect, useState} from "react";
import {Tab, TabList, Tabs} from "react-tabs";
import {useLocation} from "react-router-dom";
import Section from "../components/Section";
import PageBackground from "../components/PageBackground";
import pageBackground from '../assets/backgrounds/platform_apps.jpg';
import arrowLeft from '../assets/icons/arrows/left@2x.png';
import arrowRight from '../assets/icons/arrows/right@2x.png';
import {BottomNavigator} from "../components/Navbar";
import {useTranslation} from "react-i18next";

const PlatformApps = (t) => {
    return [
        {
            title: t('apps.secure_genomic_comparison.title'),
            description: t('apps.secure_genomic_comparison.description'),
            video: 'secure-genomic-comparison.mp4'
        },
        {
            title: t('apps.clinical_trial_matching.title'),
            description: t('apps.clinical_trial_matching.description'),
            video: 'clinical-trials-matching.mp4'
        },
        {
            title: t('apps.ancestry_profiling.title'),
            description: t('apps.ancestry_profiling.description'),
            video: 'ancestry-analysis.mp4'
        }
    ]
};

const hashToIndex = (hash) => {
    if(hash === '#secure-genomic-comparison'){
        return 0;
    }
    else if(hash === '#clinical-trials-matching'){
        return 1;
    }
    else if(hash === '#ancestry-analysis'){
        return 2;
    }
    else {
        return 0;
    }
}

const AppsPage = (props) => {
    const {t} = useTranslation();
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(hashToIndex(location.hash));
    const PlatformApp = PlatformApps(t)[tabIndex];

    useEffect(() => {
        document.body.style.backgroundColor = '#050507';

        return () => {
            document.body.style.backgroundColor = null;
        };
    }, []);

    useEffect(() => {
        setTabIndex(hashToIndex(location.hash));
    }, [location.hash]);

    const onPrevClick = () => {
        let nextIndex = tabIndex - 1;
        if(tabIndex === 0){
            nextIndex = 2;
        }
        setTabIndex(nextIndex);
    }

    const onNextClick = () => {
        let nextIndex = tabIndex + 1;
        if(tabIndex === 2){
            nextIndex = 0;
        }
        setTabIndex(nextIndex);
    }


    return (
        <div className={'Page AppsPage'}>
            <Section className={'TopSection'}>
                <PageBackground src={pageBackground}/>
                <div className={'SectionContainer'}>
                    <h1>{t('apps.title')}</h1>

                    <Tabs selectedIndex={tabIndex}
                          onSelect={index => setTabIndex(index)}
                          className={'Tabs'}>
                        <TabList className={'TabList'}>
                            <Tab>
                                <span>{t('apps.secure_genomic_comparison.title')}</span>
                            </Tab>
                            <Tab>
                                <span>{t('apps.clinical_trial_matching.title')}</span>
                            </Tab>
                            <Tab>
                                <span>{t('apps.ancestry_profiling.title')}</span>
                            </Tab>
                        </TabList>
                    </Tabs>

                    <div className={'PlatformApp'}>
                        <div className={'PlatformApp__demo'}>
                            <video src={`/videos/platform-apps/${PlatformApp.video}`}
                                   muted
                                   playsInline
                                   autoPlay
                                   controls/>
                        </div>
                        <div className={'PlatformApp__info'}>
                            <div className={'PlatformApp__title'}>
                                <a className={'PlatformApp__prev-button'}
                                   onClick={onPrevClick}
                                >
                                    <img src={arrowLeft}/>
                                </a>
                                <span>
                                    {t(PlatformApp.title)}
                                </span>
                                <a className={'PlatformApp__next-button'}
                                   onClick={onNextClick}
                                >
                                    <img src={arrowRight}/>
                                </a>
                            </div>
                            <div className={'PlatformApp__description'}>
                                {t(PlatformApp.description)}
                                <br /> 
                                <br /> 
                                {t(PlatformApp.description2)}
                            </div>
                        </div>
                    </div>
                </div>
            </Section>

            <BottomNavigator
                prev={{
                    to: '/components',
                    title: t('navbar.components')
                }}
                next={{
                    to: '/roadmap',
                    title: t('navbar.roadmap')
                }}/>
        </div>
    )
};

export default AppsPage;