import _ from 'lodash';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import Section from "../components/Section";
import faces from '../assets/backgrounds/graphic_hp@2x.png';
import {BottomNavigator} from "../components/Navbar";
import SocialIcons from "../components/SocialIcons";
import {Button} from "../components/Button";
import {StakeAPYCTA, MedicalSphere} from "../components/CTAPill";
import Wallet from "../store/models/wallet";
import {useDispatch} from "react-redux";
import {getNewsAPIUrl} from "../constants";
import useFetchNews from "../hooks/useNews";

const HomePage = () => {
    const {t, i18n} = useTranslation();
    const {news} = useFetchNews(i18n.language);

    useEffect(() => {
        return () => {
            document.body.style.backgroundColor = null;
        };
    }, []);

    return (
        <div className={'Page HomePage'}>
            <Section className={'TopSection'}>
                <div className={'TextContainer'}>
                    <h2>
                        {t('home.heading')}
                    </h2>
                    <h1>
                        {t('home.title')}
                    </h1>
                    <p>
                        {t('home.description_1')}
                        <br/>
                        <br/>
                        {t('home.description_2')}
                    </p>
                    <p className={'mobile-only'}>
                        <br/>
                        <br/>
                        {t('home.social_join')}
                    </p>
                    <SocialIcons className={'mobile-only'}/>
                    <StakeAPYCTA/>
                    <MedicalSphere/>
                </div>
                <div className={'ImageContainer'}>
                    <img src={faces}/>
                </div>
            </Section>
            {
                news.length > 0 &&
                <Section className={'NewsSection'} id={"news"}>
                    <h1>
                        {t('home.recent_news')}
                    </h1>
                    <div className={'NewsItems'}>
                        {
                            _.map(_.take(news, 3), (item, index) => {
                                return (
                                    <a key={index}
                                       className={'NewsItem'}
                                       href={item.url}
                                       target={'_blank'}
                                    >
                                        <div className={'NewsItem__info'}>
                                            {`${item.date} - ${item.source}`}
                                        </div>
                                        <div className={'NewsItem__title'}>
                                            {item.title}
                                        </div>

                                    </a>
                                )
                            })
                        }
                        <Button color={'grey'}
                                href={'/news'}
                                style={{marginBottom: 40, width: 300}}
                        >
                            {t('home.view_more')}
                        </Button>
                    </div>
                </Section>
            }


            <BottomNavigator next={{
                to: '/about',
                title: t('navbar.about')
            }}/>

        </div>
    )
};

export default HomePage;