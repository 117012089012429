const translations = {
    en: {
        translation: {
            "navbar": {
                "home": "Home",
                "about": "About",
                "team": "Team",
                "advisory_council": "Advisory Council",
                "whitepaper": "Whitepaper",
                "news": "News",
                "components": "Components",
                "platform": "Platform",
                "lavita_platform": "Lavita Platform",
                "user_workflow": "User Workflow",
                "demo": "Demo",
                "apps": "Apps",
                "docs": "Docs",
                "technology": "Technology",
                "key_components": "5 Key Components",
                "decentralized_blockchain": "Decentralized Blockchain",
                "secure_data_storage": "Secure Data Storage",
                "secure_computing": "Secure Computing",
                "ai_powered_research": "AI-powered Research",
                "secure_key_management": "Secure Key Management (SKM)",
                "roadmap": "Roadmap",
                "stake": "Stake",
                "try_medical_ai_assist": "Try Medical AI Assist",
                "try_medical_evaluation_sphere": "Try Medical Evaluation Sphere",
            },
            "home": {
                "heading": "WELCOME TO LAVITA",
                "title": "The first private health data marketplace powered by AI, blockchain & YOU.",
                "description_1": "LAVITA is a blockchain-AI based next generation healthcare platform, enabling the use of massive biomedical datasets for research while preserving individual privacy and ownership of data.",
                "description_2": "Our vision is to usher in a new era of privacy-preserving genomic and health data sharing and analysis to improve 8 Billion lives globally.",
                "social_join": "Join us on social:",
                "recent_news": "Recent News",
                "view_more": "View More",
            },
            "about":{
                "heading": "ABOUT LAVITA",
                "title_1": "Next generation privacy-preserving",
                "title_2": "genomic and health data marketplace",
                "description_1": "Lavita is a blockchain-AI based next generation healthcare technology company aiming to revolutionize the way 8 billion individuals around the world diagnose, analyze, and treat health and genomic issues, leading to earlier intervention and improved outcome.",
                "description_2": "The platform brings together state-of-the-art proprietary machine learning, biomedical natural language understanding models and semantic search algorithms to easily and accurately connect patients with the most relevant clinical trials in the fastest time possible, powered by a privacy-preserving decentralized health data marketplace.",
                "core_team": "Core Team",
                "advisory_council": "Advisory Council",
                "GENERATIVE_AI&_BIOMEDICAL_NLP": "GENERATIVE AI & BIOMEDICAL NLP",
                "SECURE_COMPUTING_&_PRIVACY": "SECURE COMPUTING & PRIVACY",
                "HEALTHCARE_&_BIOMEDICAL_INDUSTRIES": "HEALTH & BIOMEDICAL INDUSTRIES",
                "coming_soon": "COMING SOON",
            },
            "news":{
                "title": "News",
            },
            "platform":{
                "heading": "PLATFORM OVERVIEW",
                "title": "Lavita Platform",
                "subtitle": "A decentralized data marketplace",
                "description_1": "Lavita is a next-generation decentralized, peer-to-peer data marketplace connecting health providers, health data contributors, and data miners. The platform empowers individuals to contribute their own private health and genomic data and be rewarded in LAVITA (Theta TNT-20 tokens) from data miners including pharmaceutical and research companies.",
                "description_2": "LAVITA tokens can in the future be used to pay for health services to healthcare institutions, and medical facilities. Lavita is the first Web3 health data platform built from the ground up leveraging blockchain, AI and privacy preserving technologies.",
                "user_workflow": "User Workflow",
                "user_workflow_card_1": "Use mobile app to create “encrypted” profile",
                "user_workflow_card_2": "Contribute and manage personal health data",
                "user_workflow_card_3": "Aggregated, encrypted, data used in studies",
                "user_workflow_card_4": "Earn digital token (cryptocurrency)",
            },
            "demo":{
                "platform_demo": "Platform Demo",
            },
            "apps":{
                "title": "Platform Apps",
                "secure_genomic_comparison": {
                    "title": "Secure Genomic Comparison",
                    "description": "By analyzing your genome data and comparing it to various databases, you can see how certain diseases are common among people who have the most similar genomes to yours.\n\nThe numbers next to a disease refer to the occurrence of each disease among those people in the database with a similar genomic profile to yours. This information can help you gain a better understanding of a genome profile and its implication for phenotypic traits and health risks."
                },
                "clinical_trial_matching": {
                    "title": "Clinical Trial Matching",
                    "description": "Clinical trials are an integral part of developing novel treatments for various diseases and disorders. And finding eligible volunteers and patients to participate in clinical trials is crucial to these trials’ success.\n\nIn our application, we securely match users with the most relevant clinical trials based on their demographic, health information, and clinical background and history. We have developed a set of state-of-the-art proprietary machine learning and biomedical natural language understanding models and algorithms to easily and accurately connect our users with the most relevant clinical trials in the fastest time possible."
                },
                "ancestry_profiling": {
                    "title": "Ancestry Profiling",
                    "description": "Genetic ancestry is a measure of individuals’ biogeographical origins, based on correlated allele frequency differences among ancestral source populations. Knowing genetic ancestry can provide useful insights about the geographic origins of individuals’ ancestors and even aid in the assessment of risk for some heritable conditions.\n\nIn this application, by leveraging genome-wide genetic variant data, including whole-genome sequences, whole exome sequences, and whole-genome genotypes, we analyze users’ raw genome data reports and determine their genetic ancestry categories. In particular, by leveraging our bioinformatic and machine learning tools and pipelines, we find and visualize the fractional estimates of ancestry components for genomic samples of users in a secure environment."
                }
            },
            "components":{
                "title": "5 Core Components of Lavita Technology",
                "description": "The Lavita platform consists of five integral modules: decentralized blockchain, secure data storage, secure computation (e.g., secure hardware, multiparty computation, homomorphic encryption, zero-knowledge proof, and differential privacy), AI supported research & validation applications (e.g., research engine, data validation tool), and Secure Key Management (SKM).",
                "sections":{
                    "01": {
                        "heading": "01 Platform Components",
                        "title": "Decentralized Blockchain powered by Theta subchain",
                        "description": "Lavita infrastructure is powered by smart contracts running on a customized Theta Subchain. This custom health data subchain enables full horizontal scalability, without sacrificing privacy and security."
                    },
                    "02": {
                        "heading": "02 Platform Components",
                        "title": "Secure Data Storage powered by ThetaEdge Store",
                        "description": "In the Lavita platform, distributed storage will be supported by decentralized storage infrastructure powered by Theta EdgeStore and Theta Edge Network. It provides a high-throughput, content-addressed, block storage model to efficiently handle large-scale genome data from different sources."
                    },
                    "03": {
                        "heading": "03 Platform Components",
                        "title": "Secure Computing with Intel SGX Technology and Theta Edge Network",
                        "description": "Data contributors in the Lavita platform send their encrypted genomic and healthcare data to the secure computing infrastructure powered by Intel SGX run by Theta edge nodes and obtain a hash value that uniquely identifies the data in the Lavita ecosystem. During the secure computing phase, Theta EdgeStore works directly with Secure Computing Nodes through the unique hash value recorded on blockchain via smart contracts."
                    },
                    "04": {
                        "heading": "04 Platform Components",
                        "title": "AI-powered Research & Data Validation",
                        "semantic_research_engine": {
                            "title": "Semantic Research Engine",
                            "description": "Lavita platform implements AI-supported Semantic Search engine and users receive well-informed genetic information and analysis based on their clinical needs. With the use of NLP based Semantic Search engine, the Lavita platform provides more accurate information to users, and healthcare institutions."
                        },
                        "data_quality_check": {
                            "title": "Data Quality Check",
                            "description": "In order to add more liquidity to the Lavita marketplace, incentive mechanisms are implemented to improve data quality. Data contributors receive more rewards for their time and quality of their contribution to data and qualitative surveys. This is enabled by the AI-Powered Data Quality Tool."
                        }
                    },
                    "05": {
                        "heading": "05 Platform Components",
                        "title": "Secure Key Management for Private Data",
                        "description": "The key to privacy-preserving computing lies in its power to enable data sharing and analysis among different entities without exposing data to theft.\n\nLavita platform utilizes privacy-preserving Secure Key Management(SKM), sensitive data can be protected and controlled during the entire data processing phases that were previously not possible."
                    }
                }
            },
            "roadmap":{
                "title": "Development Roadmap",
                "2023": {
                    "h1": "Whitepaper 1.0 publication and technical documentation\n\nDecentralized blockchain infra setup powered by Theta subchain\n\nToken generation event",
                    "h2": "LAVITA staking governance tool\n\nLAVITA AI training jobs on Theta Edge Nodes\n\nMedical AI Assist Launch (Beta)",
                },
                "2024": {
                    "h1": "Lavita Platform v1 Launch\n\u00A0\u00A0\u00A0- Healthcare ID System\n\nSecure Data Storage powered by Theta EdgeStore (Beta)\n\nHybrid Cloud Decentralized Secure Computing (Beta)",
                    "h2": "Lavita Platform v2 Launch\n\u00A0\u00A0\u00A0- Advanced Healthcare Data Analytics Tool\n\u00A0\u00A0\u00A0- AI-powered Data Validation Tool\n\nLavita decentralized governance tool (Phase 2)\n\nClinical Patient Matching System (Pilot)\n",
                }
            },
            "documents":{
                "heading": "Documents",
                "title": "Learn how to contribute, run nodes and earn rewards from the Lavita platform",
                "lavita_whitepaper": "LAVITA Whitepaper",
                "download": "Download",
            },
            "stake": {
                "title": "Stake LAVITA to help secure the Lavita Theta subchain and earn rewards.",
                "description": "Unstake your LAVITA at any time including pending rewards subject to a cooldown period.",
                "connect_your_wallet": "Connect Your Wallet",
                "available_on_ios_and_android": "Available on iOS and Android",
                "staking_paused": "LAVITA staking has been temporarily paused.",
                "disconnect": "Disconnect",
                "connected_to": "Connected to: ",
                "balance": "Balance",
                "staked_plus_rewards": "Staked + reward (est.)",
                "stake": "Stake",
                "unstake": "Unstake",
                "pending_withdrawals": "Pending Withdrawals",
                "claim": "Claim",
                "available_in": "Available in ~{{time}}",
                "available_to_claim": "Available to claim",
                "available_to_claim_legacy": "Available to claim - Legacy",
            },
            "claim": {
                "title": "Burn Character NFTs in exchange for LAVITA tokens",
                "connect_wallet": "Connect Your Wallet",
                "available_on_ios_and_android": "Available on iOS and Android",
                "paused_message": "LAVITA claiming has been temporarily paused.",
                "disconnect": "Disconnect",
                "burn_title": "Burn Character NFTs in exchange for LAVITA tokens",
                "balance": "Balance",
                "stake": "Stake Your LAVITA",
                "burn_all": "Burn All",
                "no_cards": {
                    "title": "No Cards to Burn",
                    "subtitle": "Make sure to withdraw your Lavita NFTs from ThetaDrop to a compatible wallet like MetaMask or ThetaWallet (iOS / Android)",
                    "button": "Open ThetaDrop"
                },
            },
            "modals": {
                "stake": {
                    "title": "Stake",
                    "balance": "Balance",
                    "all": "ALL",
                    "enter_amount": "Enter amount",
                    "stake": "Stake",
                    "insufficient_balance": "Insufficient balance",
                },
                "unstake": {
                    "title": "Unstake",
                    "staked": "Staked",
                    "enter_percentage": "Enter %",
                    "unstake": "Unstake",
                    "cooldown_message": "*LAVITA will be claimable after ~5 day cooldown"
                }
            },
            "cta": {
                "stake_apy": {
                    "title": "Stake LAVITA and earn up to "
                },
                "stake_apy_info": {
                    "title": "Stake and earn up to ",
                },
                "medical_sphere": {
                    "title": "Try Medical Evaluation Sphere >",
                }
            }
        }
    },
    ko: {
        translation: {
            "navbar": {
                "home": "홈",
                "about": "소개",
                "team": "팀 소개",
                "advisory_council": "자문위원회",
                "whitepaper": "사업백서",
                "news": "뉴스",
                "components": "기술요소",
                "platform": "플랫폼",
                "lavita_platform": "라비타 플랫폼",
                "user_workflow": "사용자 워크플로우",
                "demo": "데모",
                "apps": "어플리케이션",
                "docs": "문서",
                "technology": "기술",
                "key_components": "주요 기술요소",
                "decentralized_blockchain": "분산형 블록체인",
                "secure_data_storage": "보안 데이터 저장소",
                "secure_computing": "보안 컴퓨팅",
                "ai_powered_research": "AI 기반 연구",
                "secure_key_management": "보안 키 관리 (SKM)",
                "roadmap": "로드맵",
                "stake": "Stake",
                "try_medical_ai_assist": "의료 AI 질의응답 챗봇",
            },
            "home": {
                "heading": "라비타에 오신 것을 환영합니다",
                "title": "AI, 블록체인 및 당신이 관리하는 최초의 개인 헬스케어 데이터 마켓플레이스",
                "description_1": "라비타는 블록체인-AI 기반의 차세대 헬스케어 플랫폼으로, 개인의 프라이버시와 데이터 소유권을 보호하면서 대규모의 헬스케어 데이터를 연구에 활용할 수 있도록 지원합니다..",
                "description_2": "우리의 비전은 전 세계 80억 명의 삶을 개선하기 위해 프라이버시가 보호되는 헬스케어 데이터 공유의 장을 열고, 데이터 분석의 새로운 시대를 여는 것입니다.",
                "social_join": "커뮤니티에 참여하세요:",
                "recent_news": "최근 뉴스",
                "view_more": "더보기",
            },
            "about":{
                "heading": "라비타 소개",
                "title_1": "차세대 개인정보 보호 기능",
                "title_2": "헬스케어 데이터 마켓플레이스",
                "description_1": "라비타는 전 세계 80억 명의 개인이 건강 및 유전 관련 문제를 진단, 분석, 치료하는 방식을 혁신하여 의료의 질을 개선하는 것을 목표로 하는 블록체인-AI 기반 차세대 의료 기술 회사입니다.",
                "description_2": "이 플랫폼은 최신 AI, Biomedical 자연어 모델, AI기반 검색 알고리즘을 결합하고 개인 정보를 보호하는 분산형 의료 데이터 마켓플레이스를 기반으로 개인을 가장 관련성이 높은 의료기관 및 임상시험 기관과 빠른 시간 내에 정확하게 연결해 줍니다.",
                "core_team": "팀 소개",
                "advisory_council": "자문위원화",
                "GENERATIVE_AI&_BIOMEDICAL_NLP": "생성적 AI & 생체의학 NLP",
                "SECURE_COMPUTING_&_PRIVACY": "보안 컴퓨팅 & 은둔",
                "HEALTHCARE_&_BIOMEDICAL_INDUSTRIES": "보건 의료 & 생체의학 산업",
                "coming_soon": "출시 예정",
            },
            "news":{
                "title": "뉴스",
            },
            "platform":{
                "heading": "플랫폼 구성",
                "title": "라비타 플랫폼",
                "subtitle": "분산형 데이터 마켓플레이스",
                "description_1": "라비타는 의료 서비스 제공자, 건강 데이터 기여자, 데이터 채굴자를 연결하는 차세대 탈중앙화 P2P 데이터 마켓플레이스입니다. 이 플랫폼은 개인이 자신의 헬스케어 데이터를 제공하고 제약 및 연구 회사 등의 데이터 채굴자로부터 LAVITA(세타 TNT-20 토큰)로 보상을 받을 수 있도록 지원합니다.",
                "description_2": "라비타 토큰은 향후 의료 기관 및 의료 시설에서 의료 서비스 비용을 지불하는 데 사용할 수 있습니다. 라비타는 블록체인, 인공지능, 개인정보 보호 기술을 활용하여 처음부터 구축된 최초의 웹3.0 건강 데이터 플랫폼입니다.",
                "user_workflow": "사용자 워크플로우",
                "user_workflow_card_1": "모바일 앱을 사용하여 '암호화된' 프로필 만들기",
                "user_workflow_card_2": "개인 건강 데이터 공유 및 관리",
                "user_workflow_card_3": "암호화된 데이터 기반 연구 진행",
                "user_workflow_card_4": "라비타 토큰으로 보상",
            },
            "demo":{
                "platform_demo": "플랫폼 데모",
            },
            "apps":{
                "title": "플랫폼 어플리케이션",
                "secure_genomic_comparison": {
                    "title": "유전 데이터 비교 애플리케이션",
                    "description": "유전 데이터를 분석하고 다양한 데이터베이스와 비교하면 나와 가장 유사한 유전자를 가진 사람들 사이에서 특정 질병이 얼마나 흔하게 발생하는지 확인할 수 있습니다. 질병 옆의 숫자는 데이터베이스에서 나와 유사한 유전자 프로필을 가진 사람들 사이에서 각 질병의 발생률을 나타냅니다. 이 정보는 유전체 프로필과 표현형 특성 및 건강 위험에 대한 의미를 더 잘 이해하는 데 도움이 될 수 있습니다."
                },
                "clinical_trial_matching": {
                    "title": "임상 시험 매칭",
                    "description": "임상시험은 다양한 질병과 장애에 대한 새로운 치료법을 개발하는 데 필수적인 부분입니다. 그리고 임상시험에 참여할 적격 지원자와 환자를 찾는 것은 임상시험의 성공에 매우 중요한 부분입니다. 저희 애플리케이션에서는 인구통계, 건강 정보, 배경 및 이력을 바탕으로 가장 적합한 임상시험에 사용자를 안전하게 매칭합니다. 저희는 사용자와 가장 관련성이 높은 임상시험을 가장 빠른 시간 내에 쉽고 정확하게 연결하기 위해 최첨단 AI 및 헬스케어 자연어 이해 모델과 알고리즘을 개발했습니다."
                },
                "ancestry_profiling": {
                    "title": "가계도(Ancestry) 프로파일링",
                    "description": "유전적 조상 분석법은 조상 집단 간의 연관된 대립유전자 빈도 차이를 기반으로 개인의 생물지리학적 기원을 측정하는 척도입니다. 유전적 조상을 알면 개인의 조상에 대한 유용한 인사이트를 얻을 수 있으며, 일부 유전성 질환의 위험도를 평가하는 데에도 도움이 될 수 있습니다. 이 애플리케이션에서는 전체 유전자 서열을 포함한 유전자 전반의 변이 데이터를 활용하여 사용자의 유전자 데이터 보고서를 분석하고 유전적 조상 범주를 결정합니다. 특히 Biomedical 및 AI 도구와 파이프라인을 활용하여 안전한 환경에서 사용자의 유전 샘플에 대한 추정치를 찾아 시각화합니다."
                }
            },
            "components":{
                "title": "라비타 기술의 5가지 핵심 구성 요소",
                "description": "라비타 플랫폼은 분산형 블록체인, 안전한 데이터 저장 스토리지, 보안 컴퓨팅 (예: 보안 하드웨어, 다자간 계산, 동형 암호화, zero-knowledge 증명 및 차등 개인 정보 보호), AI 기반 유효성 검사 응용 프로그램(예: 검색 엔진, 데이터 유효성 검사 도구), 보안 키 관리(SKM) 등 다섯 가지 필수 모듈로 구성되어 있습니다.",
                "sections":{
                    "01": {
                        "heading": "01 플랫폼 구성 요소",
                        "title": "세타 서브체인으로 구동되는 분산형 블록체인",
                        "description": "라비타 인프라는 맞춤형 세타 서브체인에서 실행되는 스마트 컨트랙트를 기반으로 하고 있습니다. 이 맞춤형 건강 데이터 서브체인은 개인정보 보호와 보안을 유지하면서 수평적 확장을 가능하게 합니다."
                    },
                    "02": {
                        "heading": "02 플랫폼 구성 요소",
                        "title": "ThetaEdge Store에서 제공하는 보안 데이터 저장 스토리지",
                        "description": "라비타 플랫폼에서 분산 스토리지는 세타 엣지 스토어와 세타 엣지 네트워크를 기반으로 하는 분산형 스토리지 인프라에 의해 지원될 것입니다. 대용량의 데이터를 효율적으로 처리하기 위해 높은 처리량 및 블록 스토리지 모델을 제공합니다."
                    },
                    "03": {
                        "heading": "03 플랫폼 구성 요소",
                        "title": "인텔 SGX 기술과 세타 엣지 네트워크를 기반으로 한 보안 컴퓨팅",
                        "description": "라비타 플랫폼의 데이터 기여자는 암호화된 유전자 및 의료 데이터를 세타 에지 노드가 운영하는 인텔 SGX 기반의 보안 컴퓨팅 인프라로 전송하여 라비타 생태계에서 데이터를 고유하게 식별하는 해시값을 얻습니다. 보안 컴퓨팅 단계에서 세타 에지 스토어는 스마트 계약을 통해 블록체인에 기록된 고유 해시값을 통해 보안 컴퓨팅 노드와 직접 작동합니다."
                    },
                    "04": {
                        "heading": "04 플랫폼 구성 요소",
                        "title": "AI 기반 연구 및 데이터 검증",
                        "semantic_research_engine": {
                            "title": "AI기반 검색 엔진",
                            "description": "라비타 플랫폼은 AI기반의 검색 엔진을 구현하며 사용자는 요구에 따라 유전 정보와 분석을 제공 받습니다. NLP 기반 검색 엔진을 사용하여 라비타 플랫폼은 보다 정확한 정보를 제공합니다."
                        },
                        "data_quality_check": {
                            "title": "데이터 품질 검사",
                            "description": "라비타 마켓플레이스에 더 많은 유동성을 더하기 위해, 데이터 품질을 개선하기 위한 인센티브 메커니즘이 적용됩니다. 데이터 기여자는 데이터 및 설문 조사에 대한 기여의 시간과 품질에 대해 더 많은 보상을 받게 됩니다."
                        }
                    },
                    "05": {
                        "heading": "05 플랫폼 구성 요소",
                        "title": "개인 데이터 보안을 위한 안전한 키 관리",
                        "description": "개인 정보 보호 컴퓨팅의 핵심은 데이터를 노출시키지 않고 다른 기관 간의 데이터 공유와 분석을 가능하게 하는 역량에 있습니다."
                    }
                }
            },
            "roadmap":{
                "title": "개발 로드맵",
                "2023": {
                    "h1": "사업백서 1.0 릴리즈 및 기술 문서 발간\n\n세타 서브체인으로 구동되는 분산형 블록체인 인프라 셋업\n\n토큰 생성 이벤트 (Token Generation Event)",
                    "h2": "라비타 스테이킹 거버넌스 도구\n\n세타 엣지 노드에서의 라비타 AI 트레이닝 작업\n\n의료 AI 어시스트 출시 (베타)",
                },
                "2024": {
                    "h1": "라비타 플랫폼 v1 출시\n\u00A0\u00A0\u00A0- 개인 ID 시스템 기반\n\n세타 엣지스토어로 구동되는 안전한 데이터 스토리지(베타)\n\n클라우드 기반 하이브리드 분산 보안 컴퓨팅(베타)",
                    "h2": "라비타 플랫폼 V2 출시\n\u00A0\u00A0\u00A0- 고급 의료 데이터 분석 시스템\n\u00A0\u00A0\u00A0- AI 기반 데이터 검증 시스템 \n\n라비타 분산 거버넌스 (2단계)\n\n임상 환자 매칭 시스템 (파일럿)\n",
                }
            },
            "documents":{
                "heading": "문서",
                "title": "라비타 플랫폼에 기여하고, 노드를 운영하고, 보상을 받는 방법을 알아보세요.",
                "lavita_whitepaper": "라비타 사업백서",
                "download": "다운로드",
            },
            "stake": {
                "title": "라비타 스테이킹을 통해 라비타 세타 서브체인을 보호하고 보상을 받으세요.",
                "description": "언제든지 라비타를 Unstake 할 수 있습니다 (쿨다운 기간 5일).",
                "connect_your_wallet": "지갑을 연결하세요",
                "available_on_ios_and_android": "iOS와 Android에서 사용 가능",
                "staking_paused": "라비타 스테이킹이 일시적으로 일시 중지되었습니다.",
                "disconnect": "연결을 끊다",
                "connected_to": "연결됨 : ",
                "balance": "잔액",
                "staked_plus_rewards": "Stake된 LAVITA + 보상 reward:",
                "stake": "Stake",
                "unstake": "Unstake",
                "pending_withdrawals": "출금 대기 중",
                "claim": "클레임",
                "available_in": "{{time}}분 안에 클레임 가능",
                "available_to_claim": "클레임 가능",
                "available_to_claim_legacy": "클레임 가능 - Legacy",
            },
            "claim": {
                "title": "캐릭터 NFT를 Burn 하여 LAVITA 토큰으로 교환하기",
                "connect_wallet": "지갑에 연결하세요",
                "available_on_ios_and_android": "iOS 와 Android 에 가능",
                "paused_message": "LAVITA 클레임이 일시적으로 일시 중지되었습니다.",
                "disconnect": "연결 해제",
                "burn_title": "캐릭터 NFT를 Burn 하여 LAVITA 토큰으로 교환하기",
                "balance": "잔액",
                "stake": "라비타 Stake 하기",
                "burn_all": "전체 NFT를 Burn 하기",
                "no_cards": {
                    "title": "Burn할 카드가 없습니다.",
                    "subtitle": "메타마스크 또는 세타월렛(iOS/안드로이드)과 같은 호환 가능한 지갑으로 라비타 NFT를 출금해야 합니다.",
                    "button": "쎄타드랍 웹페이지"
                },
            },
            "modals": {
                "stake": {
                    "title": "Stake",
                    "balance": "잔액",
                    "all": "전체",
                    "enter_amount": "금액 입력",
                    "stake": "Stake",
                    "insufficient_balance": "잔액 부족",
                },
                "unstake": {
                    "title": "Unstake",
                    "staked": "Stake된 LAVITA",
                    "enter_percentage": "% 입력",
                    "unstake": "Unstake",
                    "cooldown_message": "*라비타는 최대 5일의 대기시간이 지나면 받을 수 있습니다."
                }
            },
            "cta": {
                "stake_apy": {
                    "title": "라비타를 스테이킹하고 최대 다음 만큼을 보상받으세요 "
                },
                "stake_apy_info": {
                    "title": "스테이킹하고 최대 다음 만큼을 보상받으세요 ",
                }
            }
        }
    }
};


export default translations;