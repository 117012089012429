const classNames = require('classnames');

export const Button = ({children, size, color, href, target, onClick, className, style, disabled}) => {
    return (
        <a className={classNames('Button', className, {
            [`Button--${color}`]: true,
            [`Button--${size}`]: true,
            [`Button--disabled`]: disabled
        })}
           style={style}
           onClick={disabled ? null : onClick}
           href={href}
           target={target}
        >
            {children}
        </a>
    )
}

