import _ from 'lodash';
import * as thetajs from "@thetalabs/theta-js";

export function copyToClipboard(str){
    //https://gist.githubusercontent.com/Chalarangelo/4ff1e8c0ec03d9294628efbae49216db/raw/cbd2d8877d4c5f2678ae1e6bb7cb903205e5eacc/copyToClipboard.js

    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0        // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0)     // Store selection if found
            : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
        document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
        document.getSelection().addRange(selected);   // Restore the original selection
    }
}

export const truncate = (hash = '', length= 4) => {
    return hash.slice(0, (length + 2)) + '...' + hash.slice((length * -1));
};

export function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export const formatWei = (amount) => {
    // Ensure we round down
    return numberWithCommas((Math.floor(parseFloat(thetajs.utils.fromWei(amount)) * 100) / 100).toFixed(2)).replace('.00', '');
}

export const formatBalance = (balance) => {
    return formatWei(balance);
}

export const isSameAddress = (address1, address2) => {
    return _.toLower(address1) === _.toLower(address2);
}

export const buildLocalizedImagePath = (language, subPath) => {
    return `/images/${language}/${subPath}@2x.png`;
};