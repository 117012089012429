import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../store/uiState";
import {RoundedModal} from "../components/Modal";
import {Button} from "../components/Button";
import Wallet from "../store/models/wallet";

export class ConnectWalletModal extends React.Component {
    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());

        if(this.props.onClose){
            this.props.onClose();
        }
    }

    onMetaMask = () => {
        this.props.dispatch(Wallet.actions.loginWithMetamask());
    }

    onThetaWallet = () => {
        this.props.dispatch(UIState.actions.hideModal());
    }

    render() {
        const {uri} = this.props;
        return (
            <RoundedModal title={'Launch wallet'}
                          className={'ConnectWalletModal'}
                          onClose={this.onClose}
            >
                <div className={'ConnectWalletModal__buttons'}>
                    <Button color={'green'}
                            size={'large'}
                            title={'Theta Wallet'}
                            className={'ConnectWalletButton'}
                            onClick={this.onThetaWallet}
                            href={uri}
                            target={'_blank'}
                    >
                        <img src={require('../assets/wallets/theta-wallet@2x.png')}/>
                    </Button>
                </div>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {title, body, buttons} = props;

    return {
        ...props,
    }
};

export default connect(mapStateToProps)(ConnectWalletModal);
