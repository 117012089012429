
const Storage = {
    getItem: key => {
        if (typeof window !== 'undefined') {
            return localStorage.getItem(key)
        }
    },

    setItem: (key, value) => {
        if (typeof window !== 'undefined') {
            localStorage.setItem(key, value);
        }
    },

    removeItem: (key) => {
        if (typeof window !== 'undefined') {
            localStorage.removeItem(key);
        }
    },

    clear: () => {
        localStorage.clear();
    }
}
export default Storage;
