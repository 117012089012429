import {useEffect, useState} from "react";
import {Tab, TabList, Tabs} from "react-tabs";
import Section from "../components/Section";
import PageBackground from "../components/PageBackground";
import pageBackground from '../assets/backgrounds/platform_apps.jpg';
import arrowLeft from '../assets/icons/arrows/left@2x.png';
import arrowRight from '../assets/icons/arrows/right@2x.png';
import {BottomNavigator} from "../components/Navbar";
import {useTranslation} from "react-i18next";


const hashToIndex = (hash) => {
    if(hash === '#secure-genomic-comparison'){
        return 0;
    }
    else if(hash === '#clinical-trials-matching'){
        return 1;
    }
    else if(hash === '#ancestry-analysis'){
        return 2;
    }
    else {
        return 0;
    }
}

const Demo = (props) => {
    const {t} = useTranslation();
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    useEffect(() => {
        const width = document.getElementsByClassName("PlatformApp__youtube")[0].offsetWidth;
        const height = document.getElementsByClassName("PlatformApp__youtube")[0].offsetHeight;
        setWidth(width);
        setHeight(height);
        document.body.style.backgroundColor = '#050507';
        return () => {
            document.body.style.backgroundColor = null;
        };
    }, []);



    return (
        <div className={'Page AppsPage'}>
            <Section className={'TopSection'}>
                <PageBackground src={pageBackground}/>
                <div className={'SectionContainer'}>
                    <h1>{t('demo.platform_demo')}</h1>

                    <div className={'PlatformApp'}>
                    <div className={'PlatformApp__youtube'}>
                    <iframe width={width} height={height} src="https://www.youtube.com/embed/opYs_3sMRFI" title="Lavita AI Platform Demo at ThetaCon 2023" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>

                        </div>
                    </div>
                </div>
            </Section>

            <BottomNavigator
                prev={{
                    to: '/apps',
                    title: 'Apps'
                }}
                next={{
                    to: '/roadmap',
                    title: 'Roadmap'
                }}/>
        </div>
    )
};

export default Demo;