import _ from 'lodash';
import Section from "../components/Section";
import PageBackground from "../components/PageBackground";
import {Button} from "../components/Button";
import docsBackground from '../assets/backgrounds/documents.jpg';
import {BottomNavigator} from "../components/Navbar";
import UIState from "../store/uiState";
import {
    CardSeries,
    ClaimableCardTypesByID,
    CLAIMING_DISABLED,
    ContractAddresses,
    ModalTypes,
    STAKING_DISABLED
} from "../constants";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {formatBalance, truncate} from "../utils";
import Wallet from "../store/models/wallet";
import {useEffect} from "react";
import {usePageVisibility} from "../hooks/usePageVisibility";
import {TokenIdsToCardType, TokenIdsToEdition} from "../NFTMetadata";
import {pushTo} from "../utils/history";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const selectCurrentAccount = state => state?.models?.currentAccount;
export const selectCurrentBalances = state => state?.models?.balances;
export const selectClaimableCards = state => state?.models?.claimableCards;

const prepareClaimableCard = (claimableCard) => {
    const {series, tokenId} = claimableCard;

    return {
        ...ClaimableCardTypesByID[TokenIdsToCardType[series][tokenId]],
        edition: TokenIdsToEdition[series][tokenId],
        ...claimableCard
    }
};

const ExampleClaimableCards = [
    {
        "series": CardSeries.TWO,
        "tokenId": "567",
        "reward": "3200000000000000000000"
    },
    {
        "series": CardSeries.TWO,
        "tokenId": "566",
        "reward": "280000000000000000000"
    },
    {
        "series": CardSeries.ONE,
        "tokenId": "1027",
        "reward": "4000000000000000000000"
    },
    {
        "series": CardSeries.TWO,
        "tokenId": "396",
        "reward": "280000000000000000000"
    },
    // {
    //     "series": CardSeries.ONE,
    //     "tokenId": "4",
    //     "reward": "100000000000000000000"
    // },
    // {
    //     "series": CardSeries.TWO,
    //     "tokenId": "5",
    //     "reward": "3200000000000000000000"
    // },
    // {
    //     "series": CardSeries.TWO,
    //     "tokenId": "6",
    //     "reward": "280000000000000000000"
    // },
    // {
    //     "series": CardSeries.TWO,
    //     "tokenId": "7",
    //     "reward": "280000000000000000000"
    // },
    // {
    //     "series": CardSeries.TWO,
    //     "tokenId": "8",
    //     "reward": "80000000000000000000"
    // },
    // {
    //     "series": CardSeries.TWO,
    //     "tokenId": "9",
    //     "reward": "80000000000000000000"
    // }
];

const ClaimableCard = ({card, onClaim}) => {
    return (
        <div className={'ClaimableCard'}>
            <img className={'ClaimableCard__image'}
                 src={`${card.image}?h=300`}
            />
            <div className={'ClaimableCard__amount'}>
                {
                    formatBalance(card.reward)
                }
            </div>
            <Button className={'ClaimableCard__burn-button'}
                    color={'transparent-green'}
                    size={'small'}
                    onClick={() => {
                        onClaim(card);
                    }}
            >
                BURN
            </Button>

        </div>
    )
};

const ClaimPage = () => {
    const {t} = useTranslation();
    const isVisible = usePageVisibility();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const account = useSelector(selectCurrentAccount);
    const balances = useSelector(selectCurrentBalances);
    let claimableCards = useSelector(selectClaimableCards);
    claimableCards = _.map(claimableCards, prepareClaimableCard);

    const onClaim = ({series, tokenId}) => {
        if(series === CardSeries.ONE){
            dispatch(Wallet.actions.claim([tokenId], []));
        }
        else if(series === CardSeries.TWO){
            dispatch(Wallet.actions.claim([], [tokenId]));
        }
    }

    const onClaimAll = () => {
        const s1TokenIds = _.filter(claimableCards, {series: CardSeries.ONE}).map(card => card.tokenId);
        const s2TokenIds = _.filter(claimableCards, {series: CardSeries.TWO}).map(card => card.tokenId);
        dispatch(Wallet.actions.claim(s1TokenIds, s2TokenIds));
    }

    const onMetaMask = () => {
        dispatch(Wallet.actions.loginWithMetamask());
    }

    const onThetaWallet = () => {
        dispatch(Wallet.actions.loginWithWalletConnect());
    }

    const onDisconnect = () => {
        dispatch(Wallet.actions.disconnect());
    }

    const onStakeLavita = () => {
        navigate('/stake');
    }

    useEffect(() => {
        (async () => {
            await dispatch(Wallet.actions.tryToRecoverWalletSession());
            dispatch(Wallet.actions.getClaimableCards());
        })();
    }, []);

    useEffect(() => {
        if (isVisible && account && !_.isEmpty(balances)) {
            dispatch(Wallet.actions.getBalances());
            dispatch(Wallet.actions.getClaimableCards());
        }
    }, [isVisible, account]);

    const claimTransations = {
        "claim": {
            "title": "Burn Character NFTs in exchange for LAVITA tokens",
            'connect_wallet': 'Connect Your Wallet',
            'available_on_ios_and_android': 'Available on iOS and Android',
            'paused_message': 'LAVITA claiming has been temporarily paused.',
            'disconnect': 'Disconnect',
            'burn_title': 'Burn Character NFTs in exchange for LAVITA tokens',
            'balance': 'Balance',
            'stake': 'Stake Your LAVITA',
            'burn_all': 'Burn All',
            'no_cards': {
                'title': 'No Cards to Burn',
                'subtitle': 'Make sure to withdraw your Lavita NFTs from ThetaDrop to a compatible wallet like MetaMask or ThetaWallet (iOS / Android)',
                'button': 'Open ThetaDrop'
            },

        }
    }

    return (
        <div className={'Page ClaimPage'}>
            <Section className={'TopSection'}>
                {
                    _.isNil(account) &&
                    <div className={'SectionContainer'}>
                        <h1>
                            {t('claim.title')}
                        </h1>

                        <h4>
                            {t('claim.connect_wallet')}
                        </h4>
                        <Button color={'green'}
                                size={'large'}
                                title={'Theta Wallet'}
                                className={'ConnectWalletButton'}
                                onClick={onThetaWallet}
                        >
                            <img src={require('../assets/wallets/theta-wallet@2x.png')}/>
                        </Button>
                        <div className={'ConnectWalletButtonAttachment'}>
                        {t('claim.available_on_ios_and_android')}
                        </div>
                        <Button color={'green'}
                                size={'large'}
                                title={'Metamask'}
                                className={'ConnectWalletButton'}
                                onClick={onMetaMask}
                        >
                            <img src={require('../assets/wallets/metamask@2x.png')}/>
                        </Button>
                    </div>
                }
                {
                    !_.isNil(account) &&
                    <div className={'SectionContainer'}>
                        {
                            CLAIMING_DISABLED &&
                            <div className={'WarningMessage'}>
                                {t('claim.paused_message')}
                            </div>
                        }


                        <p className={'ClaimPage__connection'}>
                            <span className={'ClaimPage__connection-title'}>Connected to: </span>
                            <span className={'ClaimPage__connection-account'}>{truncate(account)}</span>
                            <a className={'ClaimPage__connection-disconnect'}
                               onClick={onDisconnect}>
                                {t('claim.disconnect')}
                            </a>
                        </p>

                        <h1>
                        {t('claim.burn_title')}
                        </h1>

                        <div className={'AccountBalances'}>
                            <div className={'AccountBalanceRow'}>
                                <div className={'AccountBalanceRow__title'}>
                                    {t('claim.balance')}
                                </div>
                                <div className={'AccountBalanceRow__value'}>
                                    {formatBalance(balances?.lavitaOnHand)}
                                </div>
                            </div>
                        </div>

                        {
                            balances?.lavitaOnHand > 0 &&
                            <Button color={'green'}
                                    size={'medium'}
                                    className={'ClaimPage__stake-lavita-button'}
                                    onClick={onStakeLavita}
                            >
                                {t('claim.stake')}
                            </Button>
                        }


                        <div className={'ClaimPage__claimable-cards'}>
                            {
                                _.map(claimableCards, (card) => {
                                    return (
                                        <ClaimableCard card={card}
                                                       key={`${card.series}-${card.tokenId.toString()}`}
                                                       onClaim={onClaim}
                                        />
                                    )
                                })
                            }

                        </div>

                        {
                            claimableCards.length > 0 &&
                            <div className={'ClaimPage__burn-all-button-wrapper'}>
                                <Button className={'ClaimPage__burn-all-button'}
                                        color={'transparent-green'}
                                        size={'medium'}
                                        onClick={onClaimAll}
                                >
                                    {t('claim.burn_all')}
                                </Button>
                            </div>
                        }

                        {
                            claimableCards.length === 0 &&
                            <div className={'ClaimPage__no-cards'}>
                                <h3>{t('claim.no_cards.title')}</h3>
                                <p>{t('claim.no_card.subtitle')}</p>
                                <Button className={'ClaimPage__burn-all-button'}
                                        color={'transparent-green'}
                                        size={'medium'}
                                        href={'https://lavita.thetadrop.com/'}
                                >
                                    {t('claim.no_cards.button')}
                                </Button>
                            </div>
                        }

                    </div>
                }
            </Section>

            <BottomNavigator
                prev={{
                    to: '/stake',
                    title: t('navbar.stake')
                }}
                next={{
                    to: '/',
                    title: t('navbar.home')
                }}/>
        </div>
    )
};

export default ClaimPage;