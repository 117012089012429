import Section from "../components/Section";
import {BottomNavigator} from "../components/Navbar";
import {useTranslation} from "react-i18next";
import {buildLocalizedImagePath} from "../utils";

const ComponentsPage = () => {
    const {t, i18n} = useTranslation();
    const language = i18n.language;
    const components00 = buildLocalizedImagePath(language, 'components/00');
    const components01 = buildLocalizedImagePath(language, 'components/01');
    const components02 = buildLocalizedImagePath(language, 'components/02');
    const components03 = buildLocalizedImagePath(language, 'components/03');
    const components04 = buildLocalizedImagePath(language, 'components/04');
    const components04a = buildLocalizedImagePath(language, 'components/04a');
    const components05 = buildLocalizedImagePath(language, 'components/05');

    return (
        <div className={'Page ComponentsPage'}>
            <Section className={'TopSection'}
                     id={'key-components'}
            >
                <div className={'TextContainer'}>
                    <h1>
                        {t('components.title')}
                    </h1>
                    <p>
                        {t('components.description')}
                    </p>
                </div>
                <div className={'ImageContainer'}>
                    <img src={components00}/>
                </div>
            </Section>

            <Section className={'ComponentSection ComponentSection--01'}
                     id={'decentralized-blockchain'}
            >
                <div className={'SectionContainer'}>
                    <h2>{t('components.sections.01.heading')}</h2>
                    <h1>
                        {t('components.sections.01.title')}
                    </h1>
                    <p>{t('components.sections.01.description')}</p>
                    <img src={components01}
                         className={'ComponentSection__graphic ComponentSection--graphic01'}/>
                </div>
            </Section>

            <Section className={'ComponentSection ComponentSection--02'}
                     id={'secure-data-storage'}>
                <div className={'SectionContainer'}>
                    <h2>{t('components.sections.02.heading')}</h2>
                    <h1>
                        {t('components.sections.02.title')}
                    </h1>
                    <p>{t('components.sections.02.description')}</p>
                    <img src={components02}
                         className={'ComponentSection__graphic ComponentSection--graphic02'}/>
                </div>
            </Section>

            <Section className={'ComponentSection ComponentSection--03'}
                     id={'secure-computing'}>
                <div className={'SectionContainer'}>
                    <h2>{t('components.sections.03.heading')}</h2>
                    <h1>
                        {t('components.sections.03.title')}
                    </h1>
                    <p>{t('components.sections.03.description')}</p><img src={components03}
                         className={'ComponentSection__graphic ComponentSection--graphic03'}/>
                </div>
            </Section>

            <Section className={'ComponentSection ComponentSection--04'}
                     id={'ai-powered-research'}>
                <div className={'SectionContainer'}>
                    <h2>{t('components.sections.04.heading')}</h2>
                    <h1>{t('components.sections.04.title')}</h1>
                    <img src={components04}
                         className={'ComponentSection__graphic ComponentSection--graphic04'}/>
                    <h3>{t('components.sections.04.semantic_research_engine.title')}</h3>
                    <p>{t('components.sections.04.semantic_research_engine.description')}</p>
                    <img src={components04a}
                         className={'ComponentSection__graphic ComponentSection--graphic04a'}/>
                    <h3>{t('components.sections.04.data_quality_check.title')}</h3>
                    <p>{t('components.sections.04.data_quality_check.description')}</p>

                </div>
            </Section>

            <Section className={'ComponentSection ComponentSection--05'}
                     id={'secure-key-management'}>
                <div className={'SectionContainer'}>
                    <h2>{t('components.sections.05.heading')}</h2>
                    <h1>
                        {t('components.sections.05.title')}
                    </h1>
                    <p>{t('components.sections.05.description')}</p>
                    <img src={components05}
                         className={'ComponentSection__graphic ComponentSection--graphic05'}/>
                </div>
            </Section>

            <BottomNavigator
                prev={{
                    to: '/platform',
                    title: t('navbar.platform')
                }}
                next={{
                    to: '/apps',
                    title: t('navbar.apps')
                }}/>
        </div>
    )
};

export default ComponentsPage;