import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../store/uiState";
import {RoundedModal} from "../components/Modal";

export class AlertModal extends React.Component {
    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());

        if(this.props.onClose){
            this.props.onClose();
        }
    }

    render() {
        const {title, body, buttons} = this.props;
        return (
            <RoundedModal title={title}
                          className={'AlertModal'}
                          onClose={this.onClose}
            >
                <div className={'AlertModal__body'}>
                    {body}
                </div>
                <div className={'AlertModal__footer'}>
                    {buttons}
                </div>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {title, body, buttons} = props;

    return {
        ...props,
    }
};

export default connect(mapStateToProps)(AlertModal);
