import _ from 'lodash';
import React from 'react'
import {connect} from 'react-redux'
import ReactModal from 'react-modal';
import {ModalTypes} from "../constants";
import UIState from "../store/uiState";
import AlertModal from "../modals/Alert";
import LoaderModal from "../modals/Loader";
import ConnectWallet from "../modals/ConnectWallet";
import StakeModal from "../modals/Stake";
import UnstakeModal from "../modals/Unstake";

const ModalComponentByType = {
    [ModalTypes.Alert]: AlertModal,
    [ModalTypes.Loader]: LoaderModal,
    [ModalTypes.ConnectWallet]: ConnectWallet,
    [ModalTypes.Stake]: StakeModal,
    [ModalTypes.Unstake]: UnstakeModal,
};

class ModalContainer extends React.Component {
    render() {
        let modal = this.props.modal;
        let ModalComponent = (modal ? modal.component : null);

        return (
            <ReactModal
                isOpen={modal !== null}
                onRequestClose={this.props.closeModal}
                ariaHideApp={false}
                overlayClassName="ModalOverlay"
                className="Modal">
                {
                    modal &&
                    <ModalComponent
                        closeModal={this.props.closeModal}
                        {...modal.props}
                    />
                }
            </ReactModal>
        )
    }
}

class Modals extends React.Component {
    constructor() {
        super();

        this.scrollState = {};

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.props.dispatch(UIState.actions.hideModal());
    }

    getModal(idx) {
        let modalData = _.get(this.props.modals, [idx], null);
        if (modalData) {
            modalData = Object.assign({}, modalData, {
                component: ModalComponentByType[modalData.type]
            });
        }

        return modalData;
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     console.log('DID UPDATE!!!');
    //     console.log('this.props.modals == ');
    //     console.log(this.props.modals);
    //     if(this.props.modals.length === 0){
    //         // Store scroll and lock
    //
    //         const {originalOverflow, originalPaddingRight, scrollBarWidth} = this.scrollState;
    //
    //         document.body.style.overflow = originalOverflow
    //
    //         if (scrollBarWidth) {
    //             document.body.style.paddingRight = originalPaddingRight
    //         }
    //     }
    //     else{
    //         // Save initial body style
    //         const originalOverflow = document.body.style.overflow;
    //         const originalPaddingRight = document.body.style.paddingRight;
    //         console.log('originalOverflow == ' + originalOverflow);
    //
    //         // Lock body scroll
    //         document.body.style.overflow = 'hidden'
    //
    //         // Get the scrollBar width
    //         const root = document.getElementById('root') // or root
    //         const scrollBarWidth = root ? root.offsetWidth - root.scrollWidth : 0
    //
    //         // Avoid width reflow
    //         if (scrollBarWidth) {
    //             document.body.style.paddingRight = `${scrollBarWidth}px`
    //         }
    //         this.scrollState = {
    //             originalOverflow,
    //             originalPaddingRight,
    //             scrollBarWidth
    //         }
    //     }
    // }

    render() {
        let modal1 = this.getModal(0);
        let modal2 = this.getModal(1);
        let modal3 = this.getModal(2);
        let modal4 = this.getModal(3);
        let modal5 = this.getModal(4);

        return (
            <div>
                <ModalContainer modal={modal1}
                                closeModal={this.closeModal}/>
                <ModalContainer modal={modal2}
                                closeModal={this.closeModal}/>
                <ModalContainer modal={modal3}
                                closeModal={this.closeModal}/>
                <ModalContainer modal={modal4}
                                closeModal={this.closeModal}/>
                <ModalContainer modal={modal5}
                                closeModal={this.closeModal}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    modals: state.ui.modals
});

export default connect(mapStateToProps, null)(Modals)
