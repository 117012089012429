export const TelegramUrl = 'https://t.me/Lavita_AI'
export const TwitterUrl = 'https://twitter.com/LavitaAI';
export const DiscordUrl = 'https://discord.com/invite/GSxBfSmh8G';
export const MediumUrl = 'https://lavita-ai.medium.com/';
export const FacebookUrl = 'https://www.facebook.com/TODO/';
export const GithubUrl = 'https://github.com/TODO';

export const STAKING_DISABLED = false;
export const CLAIMING_DISABLED = false;
export const AI_MEDICAL_ASSIST_AVAILABLE = true;

export const Hosts = {
    API: process.env.REACT_APP_API_HOST
}

export const RequestState = {
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
    NOT_AUTHORIZED: 'NOT_AUTHORIZED',
}

export const AddressZero = "0x0000000000000000000000000000000000000000";
export const CurrentNetwork = process.env.REACT_APP_CURRENT_NETWORK;

export const NetworkInfoByChainId = {
    ['privatenet']: {
        ethRpc: 'http://localhost:18888/rpc',
        chainId: 'privatenet',
        chainIdNum: 366,
    },
    ['testnet']: {
        ethRpc: 'https://eth-rpc-api-testnet.thetatoken.org/rpc',
        chainId: 'testnet',
        chainIdNum: 365,
    },
    ['mainnet']: {
        ethRpc: 'https://eth-rpc-api.thetatoken.org',
        chainId: 'mainnet',
        chainIdNum: 361,
    }
}

export const CurrentNetworkInfo = NetworkInfoByChainId[CurrentNetwork];

export const WalletConnectProjectId = '23b3420ad7efcd2f8e6f184f58f257ff';

export const WalletConnectionTypes = {
    MetaMask: 'MetaMask',
    ThetaWallet: 'ThetaWallet',
}

export const StorageKeys = {
    CurrentAccount: 'WALLET_CURRENT_ACCOUNT'
}

export const ModalTypes = {
    ConnectWallet: 'ConnectWallet',
    Alert: 'Alert',
    Loader: 'Loader',
    Stake: 'Stake',
    Unstake: 'Unstake'
}

export const LavitaSubchainId = '360890';

export const ContractAddresses = {
    ChainRegistrarOnMainchain__LEGACY: '0x76cC1d9A21D8E769F711Ae8A01F969668117ef39',
    VCM__LEGACY: '0x4dF899942Aa5772D9AB2dDF886c1BDC49a0Be301',
    VSM__LEGACY: '0x62172bD5425D4d2c52be72cdf84E47Fe234c211d',

    Lavita: '0x46fBF4487fA1B9C70d35BD761c51c360dF9459ed',
    ChainRegistrarOnMainchain: '0xCDfb9e014a622bf4aea1bc0dFA64b172E6efFE37',
    TFuelTokenBank: '0x7B171230a7984d560445a50f75EF9aA937475aEa',
    TNT20TokenBank: '0x0C99f551aC2D69510Db41f8c8d3348cA55eF099d',
    TNT721TokenBank: '0xC25f4F9C337c1c40C72a1b7Cc10A7aAF687F6b7E',
    TNT1155TokenBank: '0xdD22395a72787722A1B789504728F98fC2F13ff8',
    VCM: '0x18879cA42780E903Da9027D7cF8b3EC49dEf76c5',
    VSM: '0x833Ca5fecb11980B0a9176dBbD4FEdCB66C436DC',
    wTHETA: '0xaf537fb7e4c77c97403de94ce141b7edb9f7fcf0',

    ClaimingContract: '0xAeE65c352eb606a4d4C7681FaeD2bE2522Ce8B04',
    CardsSeries1: '0x52948252a0cacfed9014dac320d71b083f76dd62',
    CardsSeries2: '0x9edfb4d8b9f3d698fcf04bd14d8c2befa76cbe6e',
};

export const ChainRegistrarOnMainchainContract = require("./contracts/ChainRegistrarOnMainchain.json");
export const ValidatorStakeManagerContract = require("./contracts/ValidatorStakeManager.json");
export const LavitaTokenContract = require("./contracts/LavitaToken.json")

export const TFuelTokenBankContract = require("./contracts/TFuelTokenBank.json")
export const TNT20TokenBankContract = require("./contracts/TNT20TokenBank.json")
export const TNT721TokenBankContract = require("./contracts/TNT721TokenBank.json")
export const TNT1155TokenBankContract = require("./contracts/TNT1155TokenBank.json")

export const ClaimingContract = require("./contracts/BurnNFTAndClaim.json")
export const NFTContract = require("./contracts/LavitaNFT.json")

export const CardSeries = {
    ONE: 0,
    TWO: 1
}

export const CardType = {
    // Series 1
    Neuralynx: 0,
    Encrypto: 1,
    Liberty: 2,
    Cipher: 3,
    Sentinel: 4,

    // Series 2
    Wanda: 5,
    Addy: 6,
    Byte: 7,
    Synapse: 8,
    Watson: 9
}

export const ClaimableCards = [
    {
        "id": CardType.Neuralynx,
        "type_id": "type_2ne7g1w8e6vv264b3t0yf1xuquz",
        "image": "https://user-assets-thetadrop.imgix.net/user_aa0xc7csj9i5czs30ipzc8kw4sv/coll_fhqy4qusf66f25ag7bd3cuhspna/type/image-1673043017541.png",
        "name": "Neuralynx",
        "series": CardSeries.ONE,
        "rarity": "Legendary",
        "reward": "4000000000000000000000"
    },
    {
        "id": CardType.Encrypto,
        "type_id": "type_r83q3rt3cy8gdfjrfmxuwg8fgib",
        "image": "https://user-assets-thetadrop.imgix.net/user_aa0xc7csj9i5czs30ipzc8kw4sv/coll_fhqy4qusf66f25ag7bd3cuhspna/type/image-1673043005377.png",
        "name": "Encrypto",
        "series": CardSeries.ONE,
        "rarity": "Rare",
        "reward": "350000000000000000000"

    },
    {
        "id": CardType.Liberty,
        "type_id": "type_uyh4szx5h3nensdk9x6h1n2v417",
        "image": "https://user-assets-thetadrop.imgix.net/user_aa0xc7csj9i5czs30ipzc8kw4sv/coll_fhqy4qusf66f25ag7bd3cuhspna/type/image-1673043026434.png",
        "name": "Liberty",
        "series": CardSeries.ONE,
        "rarity": "Rare",
        "reward": "350000000000000000000"
    },
    {
        "id": CardType.Cipher,
        "type_id": "type_99ihqqsjxvp9b2tqfiaqqrfgk5v",
        "image": "https://user-assets-thetadrop.imgix.net/user_aa0xc7csj9i5czs30ipzc8kw4sv/coll_fhqy4qusf66f25ag7bd3cuhspna/type/image-1673043035774.png",
        "name": "Cipher",
        "series": CardSeries.ONE,
        "rarity": "Common",
        "reward": "100000000000000000000"
    },
    {
        "id": CardType.Sentinel,
        "type_id": "type_sj1e90ctvbtzwk6py1xnd8aap15",
        "image": "https://user-assets-thetadrop.imgix.net/user_aa0xc7csj9i5czs30ipzc8kw4sv/coll_fhqy4qusf66f25ag7bd3cuhspna/type/image-1673042851222.png",
        "name": "Sentinel",
        "series": CardSeries.ONE,
        "rarity": "Common",
        "reward": "100000000000000000000"
    },
    {
        "id": CardType.Wanda,
        "type_id": "type_tbrq89i346x3nx4bf1dcgt3aqq2",
        "image": "https://user-assets-thetadrop.imgix.net/user_aa0xc7csj9i5czs30ipzc8kw4sv/coll_pdkixuxnjs8z81q06qdy0jmzrnb/type/image-1683068890979.png",
        "name": "Wizard Wanda",
        "series": CardSeries.TWO,
        "rarity": "Legendary",
        "reward": "3200000000000000000000"
    },
    {
        "id": CardType.Addy,
        "type_id": "type_kdke8axm1i56kf6hrb8tt2qwbb2",
        "image": "https://user-assets-thetadrop.imgix.net/user_aa0xc7csj9i5czs30ipzc8kw4sv/coll_pdkixuxnjs8z81q06qdy0jmzrnb/type/image-1683068955070.png",
        "name": "Professor Adelaide \"Addy\" Bloom",
        "series": CardSeries.TWO,
        "rarity": "Rare",
        "reward": "280000000000000000000"
    },
    {
        "id": CardType.Byte,
        "type_id": "type_mw3ye0wpjrb47ewybvz1zd7y050",
        "image": "https://user-assets-thetadrop.imgix.net/user_aa0xc7csj9i5czs30ipzc8kw4sv/coll_pdkixuxnjs8z81q06qdy0jmzrnb/type/image-1683068972176.png",
        "name": "Byte",
        "series": CardSeries.TWO,
        "rarity": "Rare",
        "reward": "280000000000000000000"
    },
    {
        "id": CardType.Synapse,
        "type_id": "type_cr2upjsjjrur3trejkttapj3ewd",
        "image": "https://user-assets-thetadrop.imgix.net/user_aa0xc7csj9i5czs30ipzc8kw4sv/coll_pdkixuxnjs8z81q06qdy0jmzrnb/type/image-1683068938464.png",
        "name": "Dr. Synapse",
        "series": CardSeries.TWO,
        "rarity": "Common",
        "reward": "80000000000000000000"
    },
    {
        "id": CardType.Watson,
        "type_id": "type_8bncm4iph98j8wr3dvvqgxbpeta",
        "image": "https://user-assets-thetadrop.imgix.net/user_aa0xc7csj9i5czs30ipzc8kw4sv/coll_pdkixuxnjs8z81q06qdy0jmzrnb/type/image-1683146242807.png",
        "name": "Dr. Genevieve \"Gen\" Watson",
        "series": CardSeries.TWO,
        "rarity": "Common",
        "reward": "80000000000000000000"
    },
];

export const ClaimableCardTypesByID = ClaimableCards.reduce((acc, card) => {
    acc[card.id] = card;
    return acc;
}, {});

export const ClaimableCardTypesByTypeID = ClaimableCards.reduce((acc, card) => {
    acc[card.type_id] = card;
    return acc;
}, {});

export const getNewsAPIUrl = (language) => {
    if(language === 'en') {
        return 'https://sheet2api.com/v1/RSBMaYwSHCof/lavitaai-news';
    }
    else if(language === 'ko') {
        return 'https://sheet2api.com/v1/RSBMaYwSHCof/lavitaai-news-korean';
    }
}