import Section from "../components/Section";
import PageBackground from "../components/PageBackground";
import {Button} from "../components/Button";
import docsBackground from '../assets/backgrounds/documents.jpg';
import {BottomNavigator} from "../components/Navbar";
import {useTranslation} from "react-i18next";

const DocumentsPage = () => {
    const {t} = useTranslation();

    return (
        <div className={'Page DocumentsPage'}>
            <Section className={'TopSection'}>
                <PageBackground src={docsBackground}/>
                <div className={'SectionContainer'}>
                    <h2>
                        {t('documents.heading')}
                    </h2>
                    <h1>
                        {t('documents.title')}
                    </h1>
                    <p>
                        {t('documents.lavita_whitepaper')}
                    </p>

                    <Button color={'green'}
                            size={'large'}
                            href={'https://www.lavita.ai/pdfs/Lavita_white_paper_latest.pdf'}
                            target={'_blank'}
                    >
                        {t('documents.download')}
                    </Button>
                </div>
            </Section>

            <BottomNavigator
                prev={{
                    to: '/roadmap',
                    title: t('navbar.roadmap')
                }}
                next={{
                    to: '/',
                    title: t('navbar.home')
                }}/>
        </div>
    )
};

export default DocumentsPage;