import {NavLink} from "react-router-dom";
import Wallet, {selectStakingAPY} from "../store/models/wallet";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const sendGtagEvent = () => {
    if (window.gtag) {
        window.gtag("event", "click", {
            "event_category": "click",
            "event_label": "go-to-lavita-ai"
        });
    }
}
export const CTAPill = ({ text, onClick, href, style }) => {
    return (
        <NavLink className={'CTAPill'}
                 style={style}
                 to={href}
                 onClick={onClick}
        >
            {text}
        </NavLink>
    )
};

export const StakeAPYCTA = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const stakingAPY = useSelector(selectStakingAPY);

    useEffect(() => {
        dispatch(Wallet.actions.fetchStakingAPY());
    }, []);

    return (
        <CTAPill text={
            <>
                <span>{t('cta.stake_apy.title')}</span>
                <span className={'color--green'}>{`${stakingAPY ? stakingAPY : '~' }% APY`}</span>
                <span> &gt;</span>
            </>}
                 href={'/stake'}
        />
        
    )
}

export const StakeAPYInfo = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const stakingAPY = useSelector(selectStakingAPY);

    useEffect(() => {
        dispatch(Wallet.actions.fetchStakingAPY());
    }, []);

    return (
        <CTAPill text={
            <>
                <span>{t('cta.stake_apy_info.title')}</span>
                <span className={'color--green'}>{`${stakingAPY ? stakingAPY : '~' }% APY`}</span>
            </>}
        />
    )
}

export const MedicalSphere = () => {
    const {t} = useTranslation();
    return (
        <a className={'CTAPill'}
            href={'https://assist.lavita.ai/evaluation'}
            target={'_blank'}
            onClick={sendGtagEvent}>
            <>
                <span>{t('cta.medical_sphere.title')}</span>
            </>
        </a>
        
    )
}

export default CTAPill;