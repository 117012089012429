import { combineReducers } from 'redux';
import UIState from './uiState';
import ModelState from './modelState';
import modelTypes from './models';

ModelState.init(modelTypes);

const reducer = combineReducers({
    models: ModelState.reducer,
    ui: UIState.reducer
});
export { reducer as default };
