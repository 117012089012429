import _ from 'lodash';
import {post, get, put, authHeaders} from "../../utils/fetch";
import toast from 'react-hot-toast';
import {
    Urls,
    RequestState,
    Hosts,
    StorageKeys,
    ModalTypes,
    AddressZero,
    WalletConnectionTypes,
    WalletConnectProjectId,
    NetworkInfoByChainId,
    CurrentNetwork,
    CurrentNetworkInfo,
    LavitaTokenContractAddress,
    LavitaTokenContractInfo,
    MetachainContractAddresses,
    ValidatorStakeManagerContractInfo,
    LavitaSubchainId,
    ContractAddresses,
    ValidatorStakeManagerContract,
    LavitaContract, LavitaTokenContract, ChainRegistrarOnMainchainContract,
    ClaimingContract,
    STAKING_DISABLED, CLAIMING_DISABLED, NFTContract
} from "../../constants";
import {BigNumber, ethers, FixedNumber, providers} from "ethers";
import BigNumberJS from 'bignumber.js';
import WalletConnectProvider from "@walletconnect/web3-provider";
import * as thetajs from '@thetalabs/theta-js';
import Storage from '../../utils/storage';
import {store} from "../index";
import UIState from "../uiState";
import {Button} from "../../components/Button";
import {formatBalance, getTimestampAtBlock} from "../../utils";
import {EthereumProvider} from '@walletconnect/ethereum-provider'
import storage from "../../utils/storage";
import i18n from "i18next";

const getReduxState = () => {
    return store.getState();
}

// ===========================
// HELPERS
// ===========================

const buildThetaProvider = () => {
    const providerRPC = {
        name: CurrentNetworkInfo.chainId,
        rpc: CurrentNetworkInfo.ethRpc,
        chainId: CurrentNetworkInfo.chainIdNum,
    };
    const provider = new providers.JsonRpcProvider(
        providerRPC.rpc,
        {
            chainId: providerRPC.chainId,
            name: `theta-${providerRPC.name}`,
        }
    );

    return provider;
}

async function switchEthereumChain(chainId) {
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: chainId}],
        });
    } catch (e) {
        if (e.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x169', // 361 in decimal
                            chainName: 'Theta Mainnet',
                            nativeCurrency: {
                                name: 'TFUEL',
                                symbol: 'TFUEL',
                                decimals: 18
                            },
                            blockExplorerUrls: ['https://explorer.thetatoken.org'],
                            rpcUrls: ['https://eth-rpc-api.thetatoken.org/rpc'],
                        },
                    ],
                });
            } catch (addError) {
                console.error(addError);
            }
        }
        console.error(e)
    }
}

const isMobileBrowser = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const createWalletConnectV2Provider = async (dispatch) => {
    console.log('isMobileBrowser() == ', isMobileBrowser());
    console.log('navigator.userAgent == ', navigator.userAgent);
    const wcProvider = await EthereumProvider.init({
        projectId: WalletConnectProjectId,
        chains: [`${NetworkInfoByChainId[CurrentNetwork].chainIdNum}`],
        showQrModal: !isMobileBrowser(),
        rpcMap: {
            ["361"]: NetworkInfoByChainId['mainnet'].ethRpc,
            ["365"]: NetworkInfoByChainId['testnet'].ethRpc,
            ["366"]: NetworkInfoByChainId['privatenet'].ethRpc,
        },
        metadata: {
            name: "LAVITA.ai",
            description: "LAVITA.ai",
            url: 'lavita.ai',
            icons: ["https://www.lavita.ai/android-chrome-512x512.png"],
        },
        /*
        qrModalOptions: {
            mobileWallets: [
                {
                    id: '43832260665ea0d076f9af1ee157d580bb0eb44ca0415117fef65666460a2652',
                    name: 'Theta',
                    links: {
                        native: 'wc'

                    },
                },
            ],
            // desktopWallets: [
            //     {
            //         id: '43832260665ea0d076f9af1ee157d580bb0eb44ca0415117fef65666460a2652',
            //         name: 'Theta (Web)',
            //         links: {
            //             universal: 'wallet.thetatoken.org',
            //         },
            //     },
            // ],
            walletImages: {
                '43832260665ea0d076f9af1ee157d580bb0eb44ca0415117fef65666460a2652': 'https://imagedelivery.net/_aTEfDRm7z3tKgu9JhfeKA/d4afb810-5925-4f00-4ebb-d180fcf29000/lg'
            },
            explorerRecommendedWalletIds: ['43832260665ea0d076f9af1ee157d580bb0eb44ca0415117fef65666460a2652'],//'NONE',
            explorerExcludedWalletIds: 'ALL',
            enableExplorer: false
        }
         */
    });

    wcProvider.on("connect", (event) => {
        console.log("connect wc", event);
    });
    wcProvider.on("disconnect", (event) => {
        console.log("disconnect wc", event);
        //TODO logout
        dispatch(Wallet.actions.disconnect());
    });
    wcProvider.on("chainChanged", (event) => {
        console.log("chainChanged wc", event)
    });
    wcProvider.on("accountsChanged", (event) => {
        console.log("accountsChanged wc", event)
    });
    wcProvider.on('display_uri', (uri) => {
        if (isMobileBrowser()) {
            dispatch(UIState.actions.showModal(ModalTypes.ConnectWallet, {
                uri: uri,
            }));
        }
    });

    return wcProvider;
}

function getValidatorAddressForAccount(address, validators) {
    validators = _.sortBy(validators, (v) => v);
    const hexString = _.toLower(address).slice(2);
    const lastHexDigit = hexString[hexString.length - 1];
    const secondLastHexDigit = hexString[hexString.length - 2];
    const combinedHex = parseInt(lastHexDigit + secondLastHexDigit, 16);
    const idx = combinedHex % validators.length;

    return validators[Math.min(idx, validators.length - 1)];
}

async function getValidatorAddresses(chainRegistrarOnMainchainContract) {
    const {dynasty} = await chainRegistrarOnMainchainContract.getDynasty();
    const {
        validators,
        shareAmounts
    } = await chainRegistrarOnMainchainContract.getValidatorSet(LavitaSubchainId, dynasty);
    console.log('validators', validators);
    console.log('shareAmounts', shareAmounts);

    return validators;
}

const showLoader = (title, subtitle) => {
    store.dispatch(UIState.actions.showLoader(title, subtitle));
}

const sleepUntil = async (f, timeoutMs) => {
    return new Promise((resolve, reject) => {
        const timeWas = new Date();
        const wait = setInterval(function () {
            if (f()) {
                console.log("resolved after", new Date() - timeWas, "ms");
                clearInterval(wait);
                resolve();
            } else if (new Date() - timeWas > timeoutMs) { // Timeout
                console.log("rejected after", new Date() - timeWas, "ms");
                clearInterval(wait);
                reject();
            }
        }, 20);
    });
}

const wrapSendTransaction = async (transactionSenderFn) => {
    const state = getReduxState();
    const loginType = selectLoginType(state);
    const provider = selectProvider(state);

    if (provider.readonly !== true) {
        let appSuffix = '';
        if (loginType === WalletConnectionTypes.ThetaWallet) {
            appSuffix = '\non your Theta Wallet app';
        } else if (loginType === WalletConnectionTypes.MetaMask) {
            appSuffix = '\non MetaMask';
        }
        showLoader('Pending...', `Please review and sign the transaction${appSuffix}.`);
        const txResponse = await transactionSenderFn();
        showLoader('Waiting...', 'Transaction is being processed.');
        await txResponse.wait(2);
    }
}

export const showApprovalModal = async (title, body) => {
    let hasResponded = false;
    let approved = false;
    const onClose = () => {
        hasResponded = true;
    };
    const onApprove = () => {
        store.dispatch(UIState.actions.hideModal());
        hasResponded = true;
        approved = true;
    };
    const onReject = () => {
        store.dispatch(UIState.actions.hideModal());
        hasResponded = true;
    };
    store.dispatch(UIState.actions.showModal(ModalTypes.Alert, {
        title: title,
        body: body,
        buttons: [
            <Button color={'grey'}
                    size={'medium'}
                    style={{width: 140}}
                    onClick={onReject}
            >
                Cancel
            </Button>,
            <Button
                color={'green'}
                size={'medium'}
                style={{width: 140}}
                onClick={onApprove}
            >
                Continue
            </Button>
        ],
        onClose: onClose,
    }));
    await sleepUntil(() => {
        return hasResponded === true;
    }, 60000);

    return approved;
}

export const checkNFTApproval = async (contract, owner, spender, contractName) => {
    showLoader('Checking NFT approval...');

    const isApproved = await contract.isApprovedForAll(owner, spender);
    if (!isApproved) {
        const body = `This action requires your approval to burn your ${contractName} NFTs.`;
        const approved = await showApprovalModal('Approval Required', body);

        // Approve all
        if (approved) {
            showLoader('Requesting NFT approval...', 'Please review and sign the transaction.');
            const txResponse = await contract.setApprovalForAll(spender, true);
            showLoader('Waiting...', 'NFT Approval transaction is being processed.');
            await txResponse.wait(2);
        } else {
            throw Error('Did not approve');
        }
    }
}

export const checkTokenApproval = async (contract, owner, spender, amount) => {
    showLoader('Checking allowance...');
    const allowance = await contract.allowance(owner, spender);
    if (allowance.lte(amount)) {
        // Approve all
        showLoader('Allowance required', '(Transaction 1/2)\nPlease review and sign the transaction.');

        const txResponse = await contract.approve(spender, ethers.utils.parseEther('1000000000'));
        showLoader('Waiting...', 'Transaction is being processed.');
        await txResponse.wait(2);
    }
}

export const checkTokenBalance = async (contract, owner, amount) => {
    showLoader('Checking balance...');
    const balance = await contract.balanceOf(owner);
    if (balance.lt(amount)) {
        store.dispatch(UIState.actions.showModal(ModalTypes.Alert, {
            title: 'Not enough LAVITA',
            body: `This action requires a minimum balance of ${formatBalance(amount.toString())} LAVITA.`,
            buttons: [
                <Button
                    color={'green'}
                    size={'large'}
                    style={{width: 132}}
                    onClick={() => {
                        store.dispatch(UIState.actions.hideModal());
                    }}
                >
                    Okay
                </Button>
            ]
        }));
        throw Error('Not enough LAVITA');
    }
}

export const getNetwork = () => {
    return thetajs.networks.Mainnet;
}

const getContractInfoByAddress = (contractAddress) => {
    // return contractInfoByAddress[contractAddress.toLowerCase()];
}


// ACTIONS
// ===========================
export const LOGIN_WITH_METAMASK = 'LOGIN_WITH_METAMASK';
export const LOGIN_WITH_THETA_DROP = 'LOGIN_WITH_THETA_DROP';
export const FINISH_LOGIN = 'FINISH_LOGIN';
export const WALLET_CONNECTION_CHANGE = 'WALLET_CONNECTION_CHANGE';
export const INIT_WALLET_COMPLETE = 'INIT_WALLET_COMPLETE';
export const SET_PROVIDER = 'SET_PROVIDER';
export const SET_NETWORK = 'SET_NETWORK';
export const SET_BALANCES = 'SET_BALANCES';
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_STAKE_WITHDRAWLS = 'SET_STAKE_WITHDRAWLS';
export const SET_BLOCK_NUMBER = 'SET_BLOCK_NUMBER';
export const SET_CLAIMABLE_CARDS = 'SET_CLAIMABLE_CARDS';
export const SET_STAKING_APY = 'SET_STAKING_APY';

// ===========================
// SELECTORS
// ===========================
export const selectProvider = (state) => state.models.provider;
export const selectBalances = (state) => state.models.balances;
export const selectLoginType = (state) => {
    return state.models.loginType;
};
export const selectCanSendTransactions = (state) => {
    const loginType = selectLoginType(state)
    return (loginType === WalletConnectionTypes.MetaMask || loginType === WalletConnectionTypes.ThetaWallet);
};
export const selectStakingAPY = (state) => {
    return state.models.stakingAPY;
};

// ===========================
// MODEL
// ===========================
const Wallet = {
    actions: {
        fetchStakingAPY: () => async (dispatch, getState) => {
            const curStakingAPY = selectStakingAPY(getState());
            if(curStakingAPY > 0){
                // Only fetch once
                return;
            }

            const provider = buildThetaProvider();
            const vsm = new ethers.Contract(ContractAddresses.VSM, ValidatorStakeManagerContract.abi, provider);
            const lavita = new ethers.Contract(ContractAddresses.Lavita, LavitaTokenContract.abi, provider);
            const rewardPerAnnumBN = ethers.utils.parseEther('300000000'); // Assuming this is in ether and needs to be converted to wei
            const stakedAmountBN = await lavita.balanceOf(vsm.address);
            let stakingAPY;

            // Make sure stakedAmountBN is not zero
            if (!stakedAmountBN.isZero()) {
                // Adjust for scale to prevent integer division truncation to 0
                stakingAPY = rewardPerAnnumBN.mul(ethers.constants.WeiPerEther).div(stakedAmountBN).mul(100);
                stakingAPY = stakingAPY.div(ethers.constants.WeiPerEther);
                stakingAPY = Math.floor(parseFloat(stakingAPY.toString()));
            }

            if(stakingAPY){
                dispatch({
                    type: SET_STAKING_APY,
                    stakingAPY: stakingAPY,
                });
            }
        },
        fetchNetwork: () => async dispatch => {
            let network = getNetwork();
            dispatch({
                type: SET_NETWORK,
                network
            });
        },
        disconnect: () => async dispatch => {
            await dispatch({
                type: SET_PROVIDER,
                provider: null,
            });
            await dispatch({
                type: SET_ACCOUNT,
                account: null,
            });
            storage.removeItem('walletConnectionType');
            storage.clear();
            window.location.reload();
        },
        setConnected: (connected) => async dispatch => {
            dispatch({
                type: WALLET_CONNECTION_CHANGE,
                connected,
            });
        },
        tryToRecoverWalletSession: () => async (dispatch, getState) => {
            const walletConnectionType = storage.getItem('walletConnectionType');
            if (walletConnectionType) {
                if (walletConnectionType === WalletConnectionTypes.MetaMask) {
                    await dispatch(Wallet.actions.loginWithMetamask());
                } else if (walletConnectionType === WalletConnectionTypes.ThetaWallet) {
                    await dispatch(Wallet.actions.loginWithWalletConnect(true));
                }
            }
            else {
                // In-wallet browser - try to auto-login
                if (window.ethereum && window.ethereum.isThetaWallet) {
                    await dispatch(Wallet.actions.loginWithMetamask());
                }
            }
        },
        initMetamaskWallet: () => async (dispatch, getState) => {
            await dispatch(Wallet.actions.fetchNetwork());
        },
        loginWithMetamask: () => async (dispatch, getState) => {
            try {
                showLoader('Pending...', 'Please connect your account\non MetaMask.');
                await dispatch(Wallet.actions.initMetamaskWallet());
                const accounts = await window.ethereum.request({method: 'eth_requestAccounts'});
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                window.ethereum.on('chainChanged', function (networkId) {
                    window.location.reload();
                });
                if (window.ethereum.networkVersion !== '0x169') {
                    await switchEthereumChain('0x169');
                }
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                await dispatch({
                    type: SET_PROVIDER,
                    provider,
                });
                await dispatch({
                    type: SET_ACCOUNT,
                    account: address,
                });
                await dispatch(Wallet.actions.getBalances());
                await dispatch(Wallet.actions.getBlockNumber());
                await dispatch(Wallet.actions.getPendingStakeWithdrawals());

                dispatch(UIState.actions.hideModal(ModalTypes.ConnectWallet));

                storage.setItem('walletConnectionType', WalletConnectionTypes.MetaMask);

                window.ethereum.on('accountsChanged', function (accounts) {
                    dispatch(Wallet.actions.disconnect());
                    window.location.reload();
                });
            } catch (e) {
                console.log(e);
                toast.error('Failed to connect to MetaMask');
            } finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        loginWithWalletConnect: (onlyRecoverSession) => async (dispatch, getState) => {
            try {
                const walletConnectProvider = await createWalletConnectV2Provider(dispatch);
                const handleConnect = async () => {
                    const accounts = walletConnectProvider.accounts;
                    if (!_.isEmpty(accounts)) {
                        showLoader('Pending...', 'Please authorize the app\non your Theta Wallet app.');
                        const provider = new providers.Web3Provider(walletConnectProvider);
                        const signer = provider.getSigner();
                        const address = await signer.getAddress();
                        await dispatch({
                            type: SET_PROVIDER,
                            provider,
                        });
                        await dispatch({
                            type: SET_ACCOUNT,
                            account: address,
                        });
                        storage.setItem('walletConnectionType', WalletConnectionTypes.ThetaWallet);
                        await dispatch(Wallet.actions.getBalances());
                        await dispatch(Wallet.actions.getBlockNumber());
                        await dispatch(Wallet.actions.getPendingStakeWithdrawals());

                        dispatch(UIState.actions.hideModal(ModalTypes.ConnectWallet));
                    }
                };
                if (walletConnectProvider.session &&
                    walletConnectProvider?.session?.expiry > (Date.now() / 1000) &&
                    walletConnectProvider.accounts.length > 0) {
                    // recover session?
                    await handleConnect();
                } else {
                    if (!onlyRecoverSession) {
                        await walletConnectProvider.connect();
                        await handleConnect();
                    }
                }
            } catch (e) {
                console.log(e);
                toast.error(e.message);
            } finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        maybeFinishLoginWithThetaWallet: () => async (dispatch, getState) => {
            try {
                dispatch(Wallet.actions.loginWithWalletConnect(true));
            } catch (e) {
                toast.error(e.message);
            } finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        getBalances: () => async (dispatch, getState) => {
            const provider = selectProvider(getState());
            const signer = provider.getSigner();
            const address = await signer.getAddress();

            const vsm = new ethers.Contract(ContractAddresses.VSM, ValidatorStakeManagerContract.abi, provider);
            const lavita = new ethers.Contract(ContractAddresses.Lavita, LavitaTokenContract.abi, provider);
            const vsmBalance = await vsm.estimatedGovernanceTokenOwnedBy(LavitaSubchainId, address);
            const lavitaBalance = await lavita.balanceOf(address);

            dispatch({
                type: SET_BALANCES,
                balances: {
                    lavitaOnHand: lavitaBalance.toString(),
                    lavitaStaked: vsmBalance.toString(),
                }
            });
        },
        getBlockNumber: () => async (dispatch, getState) => {
            const provider = selectProvider(getState());
            const blockNumber = await provider.getBlockNumber();

            dispatch({
                type: SET_BLOCK_NUMBER,
                blockNumber: blockNumber.toString()
            });
        },
        getPendingStakeWithdrawals: () => async (dispatch, getState) => {
            const provider = selectProvider(getState());
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            const blockNumber = getState().models.blockNumber;
            const blockNumberUpdatedAt = getState().models.blockNumberUpdatedAt;

            const legacyChainRegistrarOnMainchainContract = new ethers.Contract(ContractAddresses.ChainRegistrarOnMainchain__LEGACY, ChainRegistrarOnMainchainContract.abi, signer)
            const chainRegistrarOnMainchainContract = new ethers.Contract(ContractAddresses.ChainRegistrarOnMainchain, ChainRegistrarOnMainchainContract.abi, signer)
            const legacyStakeWithdrawals = await legacyChainRegistrarOnMainchainContract.getPendingStakeWithdrawals(LavitaSubchainId, address);
            const stakeWithdrawals = await chainRegistrarOnMainchainContract.getPendingStakeWithdrawals(LavitaSubchainId, address);

            const transformWithdrawal = (withdrawal) => {
                const blocksLeft = parseInt(withdrawal.returnHeight.toString()) - parseInt(blockNumber);
                const returnDate = new Date(blockNumberUpdatedAt.getTime() + (blocksLeft * 6 * 1000));

                return {
                    ...withdrawal,
                    returnDate: returnDate
                }
            };


            dispatch({
                type: SET_STAKE_WITHDRAWLS,
                stakeWithdrawals: _.flatten([
                    _.map(stakeWithdrawals, transformWithdrawal),
                    _.map(legacyStakeWithdrawals, (withdrawal) => {
                        return {
                            ...transformWithdrawal(withdrawal),
                            legacy: true,
                        }
                    })])
            });
        },
        depositStake: (amount) => async (dispatch, getState) => {
            try {
                if (STAKING_DISABLED) {
                    toast.error(`LAVITA staking is temporarily paused.`);
                    return;
                }

                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const chainRegistrarOnMainchainContract = new ethers.Contract(ContractAddresses.ChainRegistrarOnMainchain, ChainRegistrarOnMainchainContract.abi, signer)
                const vsm = new ethers.Contract(ContractAddresses.VSM, ValidatorStakeManagerContract.abi, signer);
                const lavita = new ethers.Contract(ContractAddresses.Lavita, LavitaTokenContract.abi, signer);

                const validatorAddrs = await getValidatorAddresses(chainRegistrarOnMainchainContract);
                const validatorAddr = getValidatorAddressForAccount(address, validatorAddrs);

                const amountBN = ethers.utils.parseEther(amount);
                await checkTokenBalance(lavita, address, amountBN);
                await checkTokenApproval(lavita, address, vsm.address, amountBN);

                await wrapSendTransaction(() => {
                    return chainRegistrarOnMainchainContract.depositStake(LavitaSubchainId, validatorAddr, amountBN.toString());
                });

                await dispatch(Wallet.actions.getBalances());
                dispatch(UIState.actions.hideModal(ModalTypes.Stake));

                toast.success(`LAVITA staked`);

                return true;
            } catch (e) {
                toast.error(`Failed to stake LAVITA`);
            } finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        withdrawStake: (percentage) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                let address = await signer.getAddress();
                const chainRegistrarOnMainchainContract = new ethers.Contract(ContractAddresses.ChainRegistrarOnMainchain, ChainRegistrarOnMainchainContract.abi, signer)
                const validatorStakeManager = new ethers.Contract(ContractAddresses.VSM, ValidatorStakeManagerContract.abi, signer);

                let validatorAddrs = await getValidatorAddresses(chainRegistrarOnMainchainContract);
                let validatorAddr = getValidatorAddressForAccount(address, validatorAddrs);
                console.log('validatorAddrs == ', validatorAddrs)
                validatorAddrs = _.uniq(validatorAddrs);
                for (let i = 0; i < validatorAddrs.length; i++) {
                    const validatorSharesBalance = await validatorStakeManager.getStakerShares(LavitaSubchainId, validatorAddrs[i], address);
                    console.log('validatorSharesBalance == ', validatorAddr, validatorSharesBalance.toString());

                    if (validatorSharesBalance.toString() !== '0') {
                        validatorAddr = validatorAddrs[i];
                        console.log('Unstaking from validatorAddr ', validatorAddr);
                        break;
                    }
                }
                console.log('validatorAddr ', validatorAddr);

                const percentageToUnstake = (parseFloat(percentage) / 100); // user input is in %
                let allSharesBalance = await validatorStakeManager.shareOf(LavitaSubchainId, address);
                console.log('allSharesBalance == ', allSharesBalance.toString());
                let validatorSharesBalance = await validatorStakeManager.getStakerShares(LavitaSubchainId, validatorAddr, address);
                console.log('validatorSharesBalance == ', validatorSharesBalance.toString());
                let shares = validatorSharesBalance.mul(ethers.utils.parseEther(`${percentageToUnstake}`));
                shares = shares.div(ethers.utils.parseEther('1'));

                await wrapSendTransaction(() => {
                    return chainRegistrarOnMainchainContract.withdrawStake(LavitaSubchainId, validatorAddr, shares);
                });

                await dispatch(Wallet.actions.getBalances());
                await dispatch(Wallet.actions.getPendingStakeWithdrawals());
                dispatch(UIState.actions.hideModal(ModalTypes.Unstake));

                toast.success(`LAVITA unstaked`);

                return true;
            } catch (e) {
                console.log(e);
                toast.error(`Failed to unstake LAVITA`);
            } finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        claimWithdrawnStake: (legacy) => async (dispatch, getState) => {
            try {
                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                let chainRegistrarOnMainchainContract = new ethers.Contract(ContractAddresses.ChainRegistrarOnMainchain, ChainRegistrarOnMainchainContract.abi, signer)
                if (legacy) {
                    chainRegistrarOnMainchainContract = new ethers.Contract(ContractAddresses.ChainRegistrarOnMainchain__LEGACY, ChainRegistrarOnMainchainContract.abi, signer);
                }

                await wrapSendTransaction(() => {
                    return chainRegistrarOnMainchainContract.claimStake(LavitaSubchainId);
                });

                await dispatch(Wallet.actions.getBalances());
                await dispatch(Wallet.actions.getPendingStakeWithdrawals());

                toast.success(`Pending LAVITA claimed`);

                return true;
            } catch (e) {
                toast.error(`Failed to claim LAVITA`);
            } finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        claim: (s1TokenIds, s2TokenIds) => async (dispatch, getState) => {
            try {
                if (CLAIMING_DISABLED) {
                    toast.error(`LAVITA claiming is temporarily paused.`);
                    return;
                }
                s1TokenIds = _.map(s1TokenIds, (tokenId) => {
                    return tokenId.toString();
                })
                s2TokenIds = _.map(s2TokenIds, (tokenId) => {
                    return tokenId.toString();
                })

                showLoader('Loading...');
                const provider = selectProvider(getState());
                const signer = provider.getSigner();
                const address = await signer.getAddress();
                const claimingContract = new ethers.Contract(ContractAddresses.ClaimingContract, ClaimingContract.abi, signer);
                const cardsSeries1Contract = new ethers.Contract(ContractAddresses.CardsSeries1, NFTContract.abi, signer);
                const cardsSeries2Contract = new ethers.Contract(ContractAddresses.CardsSeries2, NFTContract.abi, signer);

                if (s1TokenIds.length > 0) {
                    await checkNFTApproval(cardsSeries1Contract, address, claimingContract.address, 'Lavita Character Series #1');
                }
                if (s2TokenIds.length > 0) {
                    await checkNFTApproval(cardsSeries2Contract, address, claimingContract.address, 'Lavita Character Series #2');
                }

                await wrapSendTransaction(() => {
                    return claimingContract.claim(s1TokenIds, s2TokenIds);
                });

                await dispatch(Wallet.actions.getBalances());
                await dispatch(Wallet.actions.getClaimableCards());

                toast.success(`NFTs burned & LAVITA claimed`);

                return true;
            } catch (e) {
                console.log(e);
                toast.error(`Failed to claim LAVITA`);
            } finally {
                dispatch(UIState.actions.hideLoader());
            }
        },
        getClaimableCards: () => async (dispatch, getState) => {
            const provider = selectProvider(getState());
            const signer = provider?.getSigner();
            const address = await signer?.getAddress();

            if(_.isNil(address)){
                return;
            }

            const claimingContract = new ethers.Contract(ContractAddresses.ClaimingContract, ClaimingContract.abi, signer)
            const claimableCards = await claimingContract.getClaimableCards(address);

            dispatch({
                type: SET_CLAIMABLE_CARDS,
                claimableCards: claimableCards
            });
        },
    },

    spec: {
        connected: false,
        provider: null,
        signer: null,
        currentAccount: null,
        balances: {
            lavitaOnHand: '0',
            lavitaStaked: '0',
        },
        stakeWithdrawals: [],
        claimableCards: [],
        stakingAPY: 0 // estimate
    },

    modelReducer: (state, type, body, action) => {
        if (action.url && action.result !== RequestState.SUCCESS)
            return state;

        if (type === SET_LOGIN_TYPE) {
            return {
                ...state,
                loginType: action.loginType
            }
        }
        if (type === SET_PROVIDER) {
            return {
                ...state,
                provider: action.provider,
            }
        }
        if (type === SET_ACCOUNT) {
            return {
                ...state,
                currentAccount: action.account,
            }
        }
        if (type === INIT_WALLET_COMPLETE) {
            return {
                ...state,
                connected: action.connected,
                provider: action.provider,
                signer: action.signer,
                currentAccount: action.currentAccount
            }
        } else if (type === SET_BALANCES) {
            return {
                ...state,
                balances: {
                    ...state.balances,
                    ...action.balances
                }
            }
        } else if (type === SET_STAKE_WITHDRAWLS) {
            return {
                ...state,
                stakeWithdrawals: action.stakeWithdrawals
            }
        } else if (type === SET_CLAIMABLE_CARDS) {
            return {
                ...state,
                claimableCards: action.claimableCards
            }
        } else if (type === SET_BLOCK_NUMBER) {
            return {
                ...state,
                blockNumber: action.blockNumber,
                blockNumberUpdatedAt: new Date()
            }
        } else if(type === SET_STAKING_APY){
            return {
                ...state,
                stakingAPY: action.stakingAPY
            }
        }

        return state;
    }
}
export default Wallet;
