import classNames from "classnames";
import Section from "../components/Section";
import {BottomNavigator} from "../components/Navbar";
import {useTranslation} from "react-i18next";

export const RoadmapCard = ({color, year, period, title, content, content2, content3, content4, content5, className}) => {
    const {t} = useTranslation();
    return (
        <div className={ classNames('RoadmapCard', `RoadmapCard--${color}`, className) }>
            <div className={'RoadmapCard__left'}>
                <div className={'RoadmapCard__year'}>
                    {year}
                </div>
                <div className={'RoadmapCard__period'}>
                    {period}
                </div>
            </div>
            <div className={'RoadmapCard__pill'}/>
            <div className={'RoadmapCard__right'}>
                {/* <div className={'RoadmapCard__title'}>
                    {title}
                </div> */}
                <div className={'RoadmapCard__content'}>
                        {t(content)}

                        {t(content2)}

                        {t(content3)}

                        {t(content4)}

                        {t(content5)}
                </div>
            </div>
        </div>
    )
}

const RoadmapPage = () => {
    const {t} = useTranslation();

    return (
        <div className={'Page RoadmapPage'}>
            <Section className={'RoadmapSection'}>
                <div className={'SectionContainer'}>
                    <h1>
                        {t('roadmap.title')}
                    </h1>

                    <div className={'RoadmapCards'}>
                        <RoadmapCard title={null}
                                     content={t('roadmap.2023.h1')}
                                     year={'2023'}
                                     period={'H1'}
                                     color={'blue'}
                        />
                        <RoadmapCard title={null}
                                     content={t('roadmap.2023.h2')}
                                     year={'2023'}
                                     period={'H2'}
                                     color={'orange'}
                        />
                        <RoadmapCard title={null}
                                     content={t('roadmap.2024.h1')}
                                     year={'2024'}
                                     period={'H1'}
                                     color={'green'}
                        />
                        <RoadmapCard title={null}
                                     content={t('roadmap.2024.h2')}
                                     year={'2024'}
                                     period={'H2'}
                                     color={'yellow'}
                        />

                    </div>
                </div>
            </Section>

            <BottomNavigator
                prev={{
                    to: '/apps',
                    title: t('navbar.apps')
                }}
                next={{
                    to: '/docs',
                    title: t('navbar.docs')
                }}/>
        </div>
    )
};

export default RoadmapPage;