import {DiscordUrl, TwitterUrl, TelegramUrl, MediumUrl} from "../constants";

import socialIconsTelegram from '../assets/icons/social/telegram@2x.png';
import socialIconsTwitter from '../assets/icons/social/twitter@2x.png';
import socialIconsDiscord from '../assets/icons/social/discord@2x.png';
import socialIconsMedium from '../assets/icons/social/medium@2x.png';
import classNames from "classnames";

export const SocialIcons = ({className}) => {
    return (
        <div className={classNames(['SocialIcons', className])}>
            <SocialIcon icon={socialIconsTelegram}
                        href={TelegramUrl}
            />
            <SocialIcon icon={socialIconsTwitter}
                        href={TwitterUrl}
            />
            <SocialIcon icon={socialIconsDiscord}
                        href={DiscordUrl}
            />
            <SocialIcon icon={socialIconsMedium}
                        href={MediumUrl}
            />
        </div>
    );
}

export const SocialIcon = ({icon, href}) => {
    return (
        <a href={href}
           target={'_blank'}
           className={'SocialIcon'}
        >
            <img src={icon}/>
        </a>
    );
}

export default SocialIcons;