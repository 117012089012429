import _ from 'lodash';
import React, {useEffect, useState} from "react";
import {NavHashLink, HashLink} from 'react-router-hash-link';
import {useNavigate, useMatch} from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import {push as Menu} from 'react-burger-menu';
import classNames from "classnames";
import SocialIcons from "./SocialIcons";
import useMediaQuery from "../hooks/useMediaQuery";

import logo from '../assets/logos/lavita@2x.png';
import hamburgerIcon from '../assets/icons/navbar/menu@2x.png';
import hamburgerXIcon from '../assets/icons/navbar/menu-x@2x.png';
import langageIcon from '../assets/icons/navbar/language@2x.png';
import {Button} from "./Button";
import {AI_MEDICAL_ASSIST_AVAILABLE} from "../constants";

const NavLink = NavHashLink;

export const HeaderLink = ({to, target, children, className, onClick, as}) => {
    if(target){
        return (
            <a href={to}
               target={target}
               className={classNames("NavbarLink", className)}
               onClick={onClick}
            >
                {children}
            </a>
        );
    }
    if(_.isNil(to)){
        return (
            <div
                className={"NavbarLink"}
                onClick={onClick}
            >
                {children}
            </div>
        )
    }
    return (
        <NavLink to={to}
                 target={target}
                 className={({ isActive }) => classNames("NavbarLink", {
                     'NavbarLink--active': isActive,
                     [className]: true
                 })}
                 onClick={onClick}
        >
            {children}
        </NavLink>
    );
}

const SubMenuLink = ({to, target, children}) => {
    return (
        <li className={'NavbarLink__submenu-item'}
            onClick={(e)=> {
                if(_.isNil(target)){
                    e.preventDefault();
                    e.stopPropagation()
                }
            }}
        >
            {
                to.startsWith('http') ?
                    <a href={to}
                       target={'_blank'}
                    >
                        {children}
                    </a>
                    :
                    <HashLink to={to}>
                        {children}
                    </HashLink>
            }
        </li>
    );
}

export const BottomNavigator = ({prev, next}) => {
    return (
        <div className={'BottomNavigator'}>
            {
                prev &&
                <NavLink to={prev.to}
                         // className='NavbarLink'
                >
                    {`< ${prev.title}`}
                </NavLink>
            }
            {
                next &&
                <NavLink to={next.to}
                         // className='NavbarLink'
                >
                    {`${next.title} >`}
                </NavLink>
            }
        </div>
    )
}

const LanguageToggleOption = ({name, language}) => {
    const { t, i18n } = useTranslation();
    return (
        <a className={classNames('LanguageToggleOption', {
            'LanguageToggleOption--selected': (i18n.language === language)
        })}
           onClick={() => {
                i18n.changeLanguage(language);
           }}
        >{name}</a>
    )
};

const LanguageToggle = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className={'LanguageToggle'}>
            <img src={langageIcon}/>
            <LanguageToggleOption name={'En'}
                                  language={'en'}
            />
            <div className={'LanguageToggle__separator'}/>
            <LanguageToggleOption name={'Ko'}
                                  language={'ko'}
            />
        </div>
    )
};

export const Navbar = ({}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(768);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const isStakePage = useMatch("/stake");

    const onOpenClick = () => {
        setIsMenuOpen(true);
    };
    const onCloseClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const sendGtagEvent = (event) => {
        if (window.gtag) {
            window.gtag("event", "click", {
                "event_category": "click",
                "event_label": "go-to-lavita-ai"
            });
        }
        setIsMenuOpen(!isMenuOpen);
    }
    const onMenuStateChange = (state) => {
        setIsMenuOpen(state.isOpen);
    };
    const links = (
        <>
            <HeaderLink to={(isMobile ? '/about' : null)}
                        onClick={() => {
                            onCloseClick();

                            if(!isMobile){
                                navigate("/about");
                            }
                        }}
            >
                {t('navbar.about')}
                <ul className="NavbarLink__submenu">
                    <SubMenuLink to={'/about#team'}>
                        {t('navbar.team')}
                    </SubMenuLink>
                    <SubMenuLink to={'/about#advisory'}>
                        {t('navbar.advisory_council')}
                    </SubMenuLink>
                    <SubMenuLink to={'/docs'}>
                        {t('navbar.whitepaper')}
                    </SubMenuLink>
                </ul>
            </HeaderLink>
            <HeaderLink to={'/news'}
                        onClick={onCloseClick}
            >
                {t('navbar.news')}
            </HeaderLink>
            <HeaderLink
                to={(isMobile ? '/platform' : null)}
                onClick={() => {
                    onCloseClick();

                    if(!isMobile){
                        navigate("/platform");
                    }
                }}
            >
                {t('navbar.platform')}
                <ul className="NavbarLink__submenu">
                    <SubMenuLink to={'/platform#overview'}>
                        {t('navbar.lavita_platform')}
                    </SubMenuLink>
                    <SubMenuLink to={'/platform#user-workflow'}>
                        {t('navbar.user_workflow')}
                    </SubMenuLink>
                    <SubMenuLink to={'/demo'}>
                        {t('navbar.demo')}
                    </SubMenuLink>
                    <SubMenuLink to={'/apps'}>
                        {t('navbar.apps')}
                    </SubMenuLink>
                </ul>
            </HeaderLink>
            <HeaderLink to={(isMobile ? '/components' : null)}
                        onClick={() => {
                            onCloseClick();

                            if(!isMobile){
                                navigate("/components");
                            }
                        }}
            >
                {t('navbar.technology')}
                <ul className="NavbarLink__submenu">
                    <SubMenuLink to={'/components#key-components'}>
                        {t('navbar.key_components')}
                    </SubMenuLink>
                    <SubMenuLink to={'/components#decentralized-blockchain'}>
                        {t('navbar.decentralized_blockchain')}
                    </SubMenuLink>
                    <SubMenuLink to={'/components#secure-data-storage'}>
                        {t('navbar.secure_data_storage')}
                    </SubMenuLink>
                    <SubMenuLink to={'/components#secure-computing'}>
                        {t('navbar.secure_computing')}
                    </SubMenuLink>
                    <SubMenuLink to={'/components#ai-powered-research'}>
                        {t('navbar.ai_powered_research')}
                    </SubMenuLink>
                    <SubMenuLink to={'/components#secure-key-management'}>
                        {t('navbar.secure_key_management')}
                    </SubMenuLink>
                </ul>
            </HeaderLink>
            <HeaderLink to={'/roadmap'}
                        onClick={onCloseClick}
            >
                {t('navbar.roadmap')}
            </HeaderLink>
            <HeaderLink to={'/stake'}
                        className={'mobile-only'}
                        onClick={onCloseClick}
            >
                {t('navbar.stake')}
            </HeaderLink>
            <HeaderLink to={'https://assist.lavita.ai'}
                        target={'_blank'}
                        className={'mobile-only'}
                        onClick={sendGtagEvent}
            >
                {t('navbar.try_medical_ai_assist')}
            </HeaderLink>
            <HeaderLink to={'https://assist.lavita.ai/evaluation'}
                        target={'_blank'}
                        className={'mobile-only'}
                        onClick={sendGtagEvent}
            >
                {t('navbar.try_medical_evaluation_sphere')}
            </HeaderLink>
        </>
    );
    const stakeButton = (
        <Button className={'Navbar__stake-button'}
                color={'green'}
                size={'small'}
                onClick={() => {
                    navigate("/stake");
                }}
        >
            {t('navbar.stake')}
        </Button>
    );
    const assistButton = (
        <Button className={'Navbar__assist-button'}
                color={'outline-grey'}
                size={'small'}
                href={'https://assist.lavita.ai'}
                target={'_blank'}
        >
            {t('navbar.try_medical_ai_assist')}
        </Button>
    );

    return (
        <div className={'Navbar'}>
            {
                isMobile &&
                <React.Fragment>
                    <a onClick={onOpenClick}
                       className={'NavbarHamburgerButton'}
                    >
                        <img src={hamburgerIcon}/>
                    </a>
                    <div className={'Navbar__content'}>
                        <NavLink to={'/'}
                                 className={'Navbar__logo-wrapper'}>
                            <img src={logo}
                                 className={'Navbar__logo mobile-only'}
                            />
                        </NavLink>
                        <div className={'Navbar__right-mobile'}>
                            <LanguageToggle/>
                            {
                                !isStakePage &&
                                stakeButton
                            }
                        </div>
                    </div>
                    <Menu isOpen={isMenuOpen}
                          disableAutoFocus
                          itemListElement='div'
                          customBurgerIcon={false}
                          customCrossIcon={false}
                          onStateChange={onMenuStateChange}>
                        <div className={'NavBarMenuHeader'}>
                            <a onClick={onCloseClick}
                               className={'NavBarMenuHeader__close'}>
                                <img src={hamburgerXIcon}/>
                            </a>
                            {links}
                        </div>
                        <SocialIcons/>
                    </Menu>
                </React.Fragment>
            }
            {
                !isMobile &&
                <div className={'Navbar__content'}>
                    <div className={'Navbar__links'}>
                        <NavLink to={'/'}>
                            <img src={logo}
                                 className={'Navbar__logo'}
                            />
                        </NavLink>
                        {links}
                        {
                            !isStakePage &&
                            stakeButton
                        }
                    </div>
                    <div className={'Navbar__right'}>
                        {
                            <LanguageToggle/>
                        }
                        {
                            assistButton
                        }
                        <SocialIcons/>
                    </div>
                </div>
            }

        </div>
    )
}
