import _ from 'lodash';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import Section from "../components/Section";
import faces from '../assets/backgrounds/graphic_hp@2x.png';
import {BottomNavigator} from "../components/Navbar";
import SocialIcons from "../components/SocialIcons";
import {getNewsAPIUrl} from "../constants";
import useFetchNews from "../hooks/useNews";

const NewsPage = () => {
    const {t, i18n} = useTranslation();
    const {news, loading} = useFetchNews(i18n.language);

    useEffect(() => {
        return () => {
            document.body.style.backgroundColor = null;
        };
    }, []);

    return (
        <div className={'Page NewsPage'}>
            <Section className={'NewsSection'} id={"news"}>
                <h1>
                    {t('news.title')}
                </h1>
                <div className={'NewsItems'}>
                    {
                        _.map(news, (item, index) => {
                            return (
                                <a key={index}
                                   className={'NewsItem'}
                                   href={item.url}
                                   target={'_blank'}
                                >
                                    <div className={'NewsItem__info'}>
                                        {`${item.date} - ${item.source}`}
                                    </div>
                                    <div className={'NewsItem__title'}>
                                        {item.title}
                                    </div>

                                </a>
                            )
                        })
                    }
                </div>
            </Section>

            <BottomNavigator next={{
                to: '/about',
                title: t('navbar.about')
            }}/>

        </div>
    )
};

export default NewsPage;