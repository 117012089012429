import { useState, useEffect } from 'react';
import i18n from 'i18next';
import {getNewsAPIUrl} from "../constants";

const useFetchNews = (language) => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchNews = async () => {
        try {
            const response = await fetch(getNewsAPIUrl(language));
            const json = await response.json();
            setNews(json);
        } catch (e) {
            // Handle error
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNews();
    }, [language]); // Dependency on language

    return { news, loading };
};

export default useFetchNews;