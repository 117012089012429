import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../store/uiState";
import {RoundedModal} from "../components/Modal";
import {Button} from "../components/Button";
import Wallet from "../store/models/wallet";
import {formatBalance} from "../utils";
import {BigNumber, ethers} from "ethers";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

export class StakeModal extends React.Component {
    constructor(props) {
        super();

        this.state = {
            amount: ''
        }
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());

        if(this.props.onClose){
            this.props.onClose();
        }
    }

    onStake = () => {
        const {amount} = this.state;
        const {t, balances} = this.props;

        if(ethers.utils.parseEther(amount).gt(BigNumber.from(balances?.lavitaOnHand))){
            alert(t('modals.stake.insufficient_balance'));
            return;
        }
        this.props.dispatch(Wallet.actions.depositStake(amount));
    }

    onAll = () => {
        const {balances} = this.props;

        this.setState({amount: ethers.utils.formatEther(balances?.lavitaOnHand)});
    }

    render() {
        const {t, balances, stakeWithdrawals} = this.props;
        const {amount} = this.state;

        return (
            <RoundedModal title={t('modals.stake.title')}
                          className={'StakeModal'}
                          onClose={this.onClose}
            >
                <div className={'AccountBalanceRow'}>
                    <div className={'AccountBalanceRow__title'}>
                        {t('modals.stake.balance')}
                    </div>
                    <div className={'AccountBalanceRow__value'}>
                        {formatBalance(balances?.lavitaOnHand)}
                    </div>
                </div>

                <div className={'ActionInputWrapper'}>
                    <div className={'Input'}>
                        <input value={amount}
                               onChange={(e) => this.setState({amount: e.target.value})}
                               placeholder={t('modals.stake.enter_amount')}
                               type="number"
                        />
                        <Button color={'grey'}
                                size={'xsmall'}
                                onClick={this.onAll}
                        >
                            {t('modals.stake.all')}
                        </Button>
                    </div>

                    <Button color={'green'}
                            size={'medium'}
                            onClick={this.onStake}
                    >
                        {t('modals.stake.stake')}
                    </Button>
                </div>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        balances: state.models.balances,
        stakeWithdrawals: state.models.stakeWithdrawals,
    }
};

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(StakeModal);

