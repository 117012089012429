import _ from 'lodash';
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import classNames from "classnames";
import Section from "../components/Section";
import bgLeft from '../assets/backgrounds/graphic_about_left@2x.png';
import bgRight from '../assets/backgrounds/graphic_about_right@2x.png';
import {BottomNavigator} from "../components/Navbar";

const teamMembersBySection = {
    core: [
        {
            avatar: 'ali@2x.png',
            name: 'Ali',
            title2: 'Business Operations',
            externalLink: 'https://alifarahanchi1.github.io/'
        },
        {
            avatar: 'feng@2x.png',
            name: 'Feng',
            title2: 'Privacy Computing',
            externalLink: 'https://galois-c.github.io/'
        },
        {
            avatar: 'ed@2x.png',
            name: 'Edward',
            title2: 'Privacy Computing',
            externalLink: 'https://edwarddou2020.github.io/'
        },
        {
            avatar: 'pedram@2x.png',
            name: 'Pedram',
            title2: 'NLP and ML',
            externalLink: 'https://phosseini.github.io/'
        },
        {
            avatar: 'reza@2x.png',
            name: 'Reza',
            title2: 'Computational Genomics',
            externalLink: 'https://rezamokhtari1.github.io/'
        },
        {
            avatar: 'mike@2x.png',
            name: 'Mike',
            title2: 'Front-End',
            externalLink: 'https://haiyangzhang1994.github.io/news/'
        }
    ],
    scientificAdvisors: [
        {
            avatar: 'advisors/jaegul-choo@2x.png',
            name: 'Jaegul Choo',
            title: 'Jaegul Choo',
            // title2: 'Associate Professor',
            title2: 'Graduate School ',
            title3: 'of AI',
            title4: 'KAIST University',
            externalLink: 'https://sites.google.com/site/jaegulchoo'
        },
        {
            avatar: 'advisors/saeed-hassanpour@2x.png',
            name: 'Saeed Hassanpour',
            title: 'Saeed Hassanpour',
            title2: 'Biomedical & Computer',
            title3: 'Science',
            title4: 'Dartmouth College',
            externalLink: 'https://www.hassanpourlab.com/saeed-hassanpour'
        },
        {
            avatar: 'advisors/tim-miller@2x.png',
            name: 'Tim Miller',
            title: 'Tim Miller',
            title2: 'Computational Health',
            title3: 'Informatics',
            title4: 'Harvard University',
            externalLink: 'https://www.linkedin.com/in/timothy-a-miller/'
        },
        {
            avatar: 'advisors/salman-avestimehr@2x.png',
            name: 'Salman Avestimehr',
            title: 'Salman Avestimehr',
            title2: 'Computer Engineering',
            title3: '& Science',
            title4: 'Uni. of So. California',
            externalLink: 'https://www.linkedin.com/in/salman-avestimehr-46aa662/'
        },
        {
            avatar: 'advisors/haixu-tang@2x.png',
            name: 'Haixu Tang',
            title: 'Haixu Tang',
            title2: 'Bioinformatics ',
            title3: '& Computing',
            title4: 'Indiana University',
            externalLink: 'https://luddy.indiana.edu/contact/profile/?Haixu_Tang'
        },
        {
            avatar: 'advisors/xiaofeng-wang@2x.png',
            name: 'XiaoFeng Wang',
            title: 'XiaoFeng Wang',
            title2: 'Center for Secure',
            title3: 'Computing',
            title4: 'Indiana University',
            externalLink: 'https://luddy.indiana.edu/contact/profile/?XiaoFeng_Wang'
        },
        {
            avatar: 'advisors/hyegi-chung.png',
            name: 'Hyegi Chung',
            title: 'Hyegi Chung',
            title2: 'Life Sciences',
            title3: 'Investments',
            title4: 'Verily Life Sciences',
            externalLink: 'https://www.linkedin.com/in/hyegichung/'
        },
    ],
    industryAdvisors: [

    ]
}

export const TeamMember = ({avatar, name, title, title2, title3, title4, title5, logo, externalLink, className}) => {
    const {t} = useTranslation();
    return (
        <a className={ classNames('TeamMember', className) }
           href={externalLink}
           target={'_blank'}
        >
            <img className={'TeamMember__avatar'}
                 src={`/images/team-avatars/${avatar}`}/>

            <div className={'TeamMember__name'}>
                {t(name)}
                {/* {t(title)} */}
                <br />
                <br />
            </div>
            <div className={'TeamMember__title'}>
                {t(title2)}
                <br />
                {t(title3)}
                <br />
                <br />
                {t(title4)}
                <br />
                {t(title5)}
            </div>
            {
                logo &&
                <div className={'TeamMember__logo-wrapper'}>
                    <img className={'TeamMember__logo'} src={logo}/>
                </div>
            }
        </a>
    )
}

const TeamSection = ({title, color, members, id}) => {
    const {t} = useTranslation();
    return (
        <div className={'Section TeamSection'}
             id={id}
        >
            <div className={'TeamSection__title'} style={{color: color}}>
                {t(title)}
            </div>
            <div className={'TeamMembers'}>
                {
                    _.map(members, (member) => {
                        return (
                            <TeamMember {...member}/>
                        )
                    })
                }
            </div>
        </div>
    )
};

const SeparatedTeamSection = ({title, color, members, id}) => {
    const {t} = useTranslation();
    return (
        <div className={'Section TeamSection'}
             id={id}
        >
            <div className={'TeamSection__title'} style={{color: color}}>
                {t(title)}
            </div>
            {/* <div className={'TeamSection__container'}>
                <div className={'TeamSection__subtitle'}>
                    {t('about.GENERATIVE_AI&_BIOMEDICAL_NLP')}
                </div>
                <div className={'TeamSection__subtitle'}>
                    {t('about.SECURE_COMPUTING_&_PRIVACY')}
                </div>
                <div className={'TeamSection__subtitle'}>
                    {t('about.HEALTHCARE_&_BIOMEDICAL_INDUSTRIES')}
                </div>
            </div> */}
            <div className={'TeamSection__container'}>
                <div className={'TeamSection__column'}>
                <div className={'TeamSection__subtitle'}>
                    {t('about.GENERATIVE_AI&_BIOMEDICAL_NLP')}
                </div>
                    <div className={'TeamMembers'}>
                        {
                            _.map(members.slice(0,3), (member) => {
                                return (
                                    <TeamMember {...member}/>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={'TeamSection__divider'}></div>
                <div className={'TeamSection__column'}>
                <div className={'TeamSection__subtitle'}>
                    {t('about.SECURE_COMPUTING_&_PRIVACY')}
                </div>
                    <div className={'TeamMembers'}>
                            {
                                _.map(members.slice(3, 6), (member) => {
                                    return (
                                        <TeamMember {...member}/>
                                    )
                                })
                            }
                    </div>
                </div>
                <div className={'TeamSection__divider'}></div>
                <div className={'TeamSection__column'}>
                <div className={'TeamSection__subtitle'}>
                    {t('about.HEALTHCARE_&_BIOMEDICAL_INDUSTRIES')}
                </div>
                    <div className={'TeamMembers'}>
                            {
                                _.map(members.slice(6), (member) => {
                                    return (
                                        <TeamMember {...member}/>
                                    )
                                })
                            }
                    </div>
                    <div className={'TeamSection__content-center'}>
                        {t('about.coming_soon')} ...
                    </div>
                </div>
            </div>
        </div>
    )
};

const AboutPage = () => {
    const {t} = useTranslation();
    useEffect(() => {
        document.body.style.backgroundColor = '#37384A';

        return () => {
            document.body.style.backgroundColor = null;
        };
    }, []);

    return (
        <div className={'Page AboutPage'}>
            <Section className={'TopSection'}
                     dark={true}
            >
                <img src={bgLeft} className={'TopSectionBG TopSectionBG--left'}/>
                <img src={bgRight} className={'TopSectionBG TopSectionBG--right'}/>

                <div className={'TextContainer'}>
                    <h2>
                        {t('about.heading')}
                    </h2>
                    <h1>
                        <span>{t('about.title_1')}</span>
                        <br/>
                        <span>{t('about.title_2')}</span>
                    </h1>
                    <p>
                        <span>{t('about.description_1')}</span>
                        <br/>
                        <br/>
                        <span>{t('about.description_2')}</span>
                    </p>
                </div>



            </Section>
            <TeamSection title={t('about.core_team')}
                         id={'team'}
                         members={teamMembersBySection.core}
                         color={'#FF7094'}
            />

            {/*These will be revealed later*/}
            <SeparatedTeamSection title={t('about.advisory_council')}
                         id={'advisory'}
                         members={teamMembersBySection.scientificAdvisors}
                         color={'#4499EB'}
            />
            {/*<TeamSection title={'INDUSTRY ADVISORS (BLOCKCHAIN & HEALTHCARE)'}*/}
            {/*             members={teamMembersBySection.industryAdvisors}*/}
            {/*             color={'#82F694'}*/}
            {/*/>*/}

            <BottomNavigator
                prev={{
                    to: '/',
                    title: t('navbar.home')
                }}
                next={{
                    to: '/platform',
                    title: t('navbar.platform')
                }}/>

        </div>
    )
};

export default AboutPage;