import React, {useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux'
import UIState from "../store/uiState";
import {RoundedModal} from "../components/Modal";
import {Button} from "../components/Button";
import Wallet from "../store/models/wallet";
import {formatBalance} from "../utils";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {StakeModal} from "./Stake";

export class UnstakeModal extends React.Component {
    constructor(props) {
        super();

        this.state = {
            amount: ''
        }
    }

    onClose = () => {
        this.props.dispatch(UIState.actions.hideModal());

        if(this.props.onClose){
            this.props.onClose();
        }
    }

    onUnstake = () => {
        const {amount} = this.state;

        this.props.dispatch(Wallet.actions.withdrawStake(amount));
    }

    render() {
        const {t, balances} = this.props;
        const {amount} = this.state;

        return (
            <RoundedModal title={t('modals.unstake.title')}
                          className={'StakeModal'}
                          onClose={this.onClose}
            >
                <div className={'AccountBalanceRow'}>
                    <div className={'AccountBalanceRow__title'}>
                        {t('modals.unstake.staked')}
                    </div>
                    <div className={'AccountBalanceRow__value'}>
                        {formatBalance(balances?.lavitaStaked)}
                    </div>
                </div>

                <div className={'ActionInputWrapper'}>
                    <input value={amount}
                           onChange={(e) => this.setState({amount: e.target.value})}
                           placeholder={t('modals.unstake.enter_percentage')}
                           max={100}
                    />
                    <Button color={'green'}
                            size={'medium'}
                            onClick={this.onUnstake}
                    >
                        {t('modals.unstake.unstake')}
                    </Button>
                </div>
                <p>{t('modals.unstake.cooldown_message')}</p>
            </RoundedModal>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        balances: state.models.balances
    }
};

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(UnstakeModal);

